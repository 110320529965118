import React, { useEffect, useState } from 'react'

import valveImageColour from '../Assets/valvmaincol.png';
import pipeWorkImageColour from '../Assets/pipeworkcol.png';
import fabricationImageColour from '../Assets/fabcol.png';
import mechanicalImageColour from '../Assets/mechcol.png';
import grabsImageColour from '../Assets/grabcol.png';
import machiningImageColour from '../Assets/machcol.png';
import valveSupplyImageColour from '../Assets/valvsupcol.png';
import sourcingImageColour from '../Assets/sourcol.png';

import valveImage from '../Assets/valvmainbw.png';
import pipeWorkImage from '../Assets/pipeworkbw.png';
import fabricationImage from '../Assets/fabbw.png';
import mechanicalImage from '../Assets/mechbw.png';
import grabsImage from '../Assets/grabbw.png';
import machiningImage from '../Assets/machbw.png';
import valveSupplyImage from '../Assets/valvsupbw.png';
import sourcingImage from '../Assets/sourcbw.png';

import allWorksBlue from '../Assets/liveworkstilebl.png';
import allWorksGreen from '../Assets/liveworkstilegr.png';

const Splash = ({ handlePasswordChange, setView, GetClientLogo, handleLogout, setType, type, fire, setChosenBoards, chosenBoards, boards, setBoards, handleAdminArea, user, account, setAccount, admin, setAdmin, liveWorksBoards, setLiveWorksBoards, valveBoards, setValveBoards, pipeWorkBoards, setPipeWorkBoards, fabricationBoards, setFabricationBoards, mechanicalBoards, setMechanicalBoards, grabsBoards, setGrabsBoards, machiningBoards, setMachiningBoards, valveSupplyBoards, setValveSupplyBoards, sourcingBoards, setSourcingBoards, setSuperAdmin }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    // let query = `{ 
    //     boards(limit: 200)
    //     {
    //         name 
    //         id
    //     } 
    // }`;

    useEffect(() => {
        GetClientLogo()
        // GetViews()

        setLoading(true)
        setLiveWorksBoards([])
        setValveBoards([])
        setPipeWorkBoards([])
        setFabricationBoards([])
        setMechanicalBoards([])
        setGrabsBoards([])
        setMachiningBoards([])
        setValveSupplyBoards([])
        setSourcingBoards([])

        fire.database()
            .ref(`/Accounts/${user.uid}`)
            .once('value')
            .then(accSnap => {
                setAccount(accSnap.val().company)
                setAdmin(accSnap.val().admin)
                setSuperAdmin(accSnap.val().superAdmin)

                fire.database()
                    .ref(`/Clients/${accSnap.val().company}/${user.uid}`)
                    .once('value')
                    .then(clientSnap => {
                        let clientData = clientSnap.val()

                        if (clientData.active === true) {

                            fire.database()
                                .ref(`/Boards/${accSnap.val().company}/${user.uid}`)
                                .once('value')
                                .then(snapshot => {
                                    var data = snapshot.val()
                                    // console.log(data.Valves)
                                    if (data != null) {
                                        for (var brdKey of Object.keys(data.Boards)) {
                                            for (var key of Object.keys(data)) {
                                                if (key === "LiveWorks") {
                                                    for (var liveWorksKey of Object.keys(data[key])) {
                                                        console.log(data[key][liveWorksKey]);
                                                        if (data[key][liveWorksKey] === true && liveWorksKey === brdKey && data.Boards[brdKey] === true) {
                                                            setLiveWorksBoards(data.LiveWorks)
                                                        }
                                                    }
                                                }
                                                else if (key === "Valves") {
                                                    for (var valveKey of Object.keys(data[key])) {
                                                        if (data[key][valveKey] === true && valveKey === brdKey && data.Boards[brdKey] === true) {
                                                            setValveBoards(data.Valves)
                                                        }
                                                    }
                                                }
                                                else if (key === "Pipe_Work") {
                                                    for (var pipeWorkKey of Object.keys(data[key])) {
                                                        if (data[key][pipeWorkKey] === true && pipeWorkKey === brdKey && data.Boards[brdKey] === true) {
                                                            setPipeWorkBoards(data.Pipe_Work)
                                                        }
                                                    }
                                                }
                                                else if (key === "Fabrication") {
                                                    for (var fabricationKey of Object.keys(data[key])) {
                                                        if (data[key][fabricationKey] === true && fabricationKey === brdKey && data.Boards[brdKey] === true) {
                                                            setFabricationBoards(data.Fabrication)
                                                        }
                                                    }
                                                }
                                                else if (key === "Mechanical") {
                                                    for (var mechanicalKey of Object.keys(data[key])) {
                                                        if (data[key][mechanicalKey] === true && mechanicalKey === brdKey && data.Boards[brdKey] === true) {
                                                            setMechanicalBoards(data.Mechanical)
                                                        }
                                                    }
                                                }
                                                else if (key === "Grabs") {
                                                    for (var grabsKey of Object.keys(data[key])) {
                                                        if (data[key][grabsKey] === true && grabsKey === brdKey && data.Boards[brdKey] === true) {
                                                            setGrabsBoards(data.Grabs)
                                                        }
                                                    }
                                                }
                                                else if (key === "Machining") {
                                                    for (var machiningKey of Object.keys(data[key])) {
                                                        if (data[key][machiningKey] === true && machiningKey === brdKey && data.Boards[brdKey] === true) {
                                                            setMachiningBoards(data.Machining)
                                                        }
                                                    }
                                                }
                                                else if (key === "Valve_Supply") {
                                                    for (var valveSupplyKey of Object.keys(data[key])) {
                                                        if (data[key][valveSupplyKey] === true && valveSupplyKey === brdKey && data.Boards[brdKey] === true) {
                                                            setValveSupplyBoards(data.Valve_Supply)
                                                        }
                                                    }
                                                }
                                                else if (key === "Sourcing") {
                                                    for (var sourcingKey of Object.keys(data[key])) {
                                                        if (data[key][sourcingKey] === true && sourcingKey === brdKey && data.Boards[brdKey] === true) {
                                                            setSourcingBoards(data.Sourcing)
                                                        }
                                                    }
                                                }
                                            }
                                            setBoards(data.Boards)
                                        }
                                    }

                                    setLoading(false)
                                });
                        }
                        else {
                            setLoading(false)
                            setError("Your user has been deactivated, please contact IME by calling (0208 599 6570) or emailing (sales@imecontracts.co.uk).")
                        }
                    });
            });
    }, [])

    // const GetViews = () => {
    //     let viewQuery = `{
    //         boards(ids: 526413661) {
    //             name
    //             views(ids: 15003878) {
    //                 id
    //                 name
    //                 settings_str
    //             }
    //         }
    //     }`


    //     fetch("https://api.monday.com/v2", {
    //         method: 'post',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
    //         },
    //         body: JSON.stringify({
    //             'query': viewQuery
    //         })
    //     })
    //         .then(res => res.json())
    //         .then(res => {
    //             // console.log("res = ", res.data.boards[0].views[0].settings_str)
    //             let splitViewSettings = res.data.boards[0].views[0].settings_str.split('},')
    //             console.log("splitViewSettings = ", splitViewSettings)
    //         })
    // }


    return (
        <section>
            <nav>
                <br />
                {loading ?
                    <div>
                        <h3>Loading...</h3>
                    </div>
                    :
                    <div style={{ marginBottom: -100 }}>
                        <p style={{ color: "red" }}>{error}</p>

                        <div className='rowSplash'>
                            <img src={allWorksBlue} alt={"All Works"} style={{ align: "flex-start", marginLeft: 10 }} height={220} width={940} onClick={() => liveWorksBoards == null || Object.keys(liveWorksBoards).length == 0 ? null : (setType(`LiveWorks`), setView('view'))} onMouseOver={e => (e.currentTarget.src = allWorksGreen)} onMouseOut={e => (e.currentTarget.src = allWorksBlue)} />
                            <div style={{ textAlign: "right", border: "1px solid", width: 300, fontSize: 14, paddingRight: 10, marginRight: 10, marginLeft: 20 }}>
                                <p style={{ margin: 0 }}>Industrial Maintenance Engineers</p>
                                <p style={{ margin: 0 }}>Unit 2 Heath Park Industrial estate</p>
                                <p style={{ margin: 0 }}>Freshwater Road</p>
                                <p style={{ margin: 0 }}>Chadwell Heath</p>
                                <p style={{ margin: 0 }}>Essex</p>
                                <p style={{ margin: 0 }}>RM8 1RX</p>
                                <p style={{ margin: 0 }}><b>Tel:</b> 0208 599 6570</p>
                                <p style={{ margin: 0 }}><b>Email:</b> sales@imecontracts.co.uk</p>
                                <p style={{ margin: 0 }}><b>Website:</b> www.imecontracts.co.uk</p>
                                <p style={{ margin: 0 }}><b>VAT Registration No.:</b> 246 1975 42</p>
                            </div>
                        </div>

                        <div className="Splash-Button-Div">

                            <img style={{ margin: 10 }} src={valveBoards == null || Object.keys(valveBoards).length == 0 ? valveImage : valveImageColour} alt={"Valve"} className="align-middle" height={200} width={300} onClick={() => valveBoards == null || Object.keys(valveBoards).length == 0 ? null : setType(`Valves`)} />
                            <img style={{ margin: 10 }} src={pipeWorkBoards == null || Object.keys(pipeWorkBoards).length == 0 ? pipeWorkImage : pipeWorkImageColour} alt={"Pipe Work"} className="align-middle" height={200} width={300} onClick={() => pipeWorkBoards == null || Object.keys(pipeWorkBoards).length == 0 ? null : setType(`Pipe_Work`)} />
                            <img style={{ margin: 10 }} src={fabricationBoards == null || Object.keys(fabricationBoards).length == 0 ? fabricationImage : fabricationImageColour} alt={"Fabrication"} className="align-middle" height={200} width={300} onClick={() => fabricationBoards == null || Object.keys(fabricationBoards).length == 0 ? null : setType(`Fabrication`)} />
                            <img style={{ margin: 10 }} src={mechanicalBoards == null || Object.keys(mechanicalBoards).length == 0 ? mechanicalImage : mechanicalImageColour} alt={"Mechanical"} className="align-middle" height={200} width={300} onClick={() => mechanicalBoards == null || Object.keys(mechanicalBoards).length == 0 ? null : setType(`Mechanical`)} />

                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={valveBoards == null || Object.keys(valveBoards).length == 0} onClick={() => setType(`Valves`)}>Valves</button> */}
                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={pipeWorkBoards == null || Object.keys(pipeWorkBoards).length == 0} onClick={() => setType(`Pipe_Work`)}>Pipe Work</button> */}
                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={fabricationBoards == null || Object.keys(fabricationBoards).length == 0} onClick={() => setType(`Fabrication`)}>Fabrication</button> */}
                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={mechanicalBoards == null || Object.keys(mechanicalBoards).length == 0} onClick={() => setType(`Mechanical`)}>Mechanical</button> */}
                            <br />

                            <img style={{ margin: 10 }} src={grabsBoards == null || Object.keys(grabsBoards).length == 0 ? grabsImage : grabsImageColour} alt={"Grabs"} className="align-middle" height={200} width={300} onClick={() => grabsBoards == null || Object.keys(grabsBoards).length == 0 ? null : setType(`Grabs`)} />
                            <img style={{ margin: 10 }} src={machiningBoards == null || Object.keys(machiningBoards).length == 0 ? machiningImage : machiningImageColour} alt={"Machining"} className="align-middle" height={200} width={300} onClick={() => machiningBoards == null || Object.keys(machiningBoards).length == 0 ? null : setType(`Machining`)} />
                            <img style={{ margin: 10 }} src={valveSupplyBoards == null || Object.keys(valveSupplyBoards).length == 0 ? valveSupplyImage : valveSupplyImageColour} alt={"Valve Supply"} className="align-middle" height={200} width={300} onClick={() => valveSupplyBoards == null || Object.keys(valveSupplyBoards).length == 0 ? null : setType(`Valve_Supply`)} />
                            <img style={{ margin: 10 }} src={sourcingBoards == null || Object.keys(sourcingBoards).length == 0 ? sourcingImage : sourcingImageColour} alt={"Sourcing"} className="align-middle" height={200} width={300} onClick={() => sourcingBoards == null || Object.keys(sourcingBoards).length == 0 ? null : setType(`Sourcing`)} />

                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={grabsBoards == null || Object.keys(grabsBoards).length == 0} onClick={() => setType(`Grabs`)}>Grabs</button> */}
                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={machiningBoards == null || Object.keys(machiningBoards).length == 0} onClick={() => setType(`Machining`)}>Machining</button> */}
                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={valveSupplyBoards == null || Object.keys(valveSupplyBoards).length == 0} onClick={() => setType(`Valve_Supply`)}>Valve Supply</button> */}
                            {/* <button className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} disabled={sourcingBoards == null || Object.keys(sourcingBoards).length == 0} onClick={() => setType(`Sourcing`)}>Sourcing</button> */}
                        </div>
                    </div>
                }
                <button style={{ margin: 5 }} className="btn btn-primary" onClick={handleLogout}>Logout</button>
                <button style={{ margin: 5 }} className="btn btn-primary" onClick={handlePasswordChange}>Change Password</button>
                {admin == true ? <button style={{ margin: 5 }} className="btn btn-primary" onClick={handleAdminArea}>Admin Area</button> : <div />}
                <br /><br />
            </nav>
        </section>
    )
}

export default Splash;