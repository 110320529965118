import React, { useState } from 'react';
// import { SliderData } from './SliderData';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import moment from 'moment'

const ImageSlider = ({ assets,assets_val }) => {
	
    const [current, setCurrent] = useState(0);
	if (!Array.isArray(assets_val) || assets_val.length <= 0) {
        return null;
    }
    const length = assets_val[0].length;
	/* console.log("length :: "+length); */
    let id = 0;
	const allow_ids   = [];
	const final_arr   = [];
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(assets) || assets.length <= 0) {
        return null;
    }

	
	/* 
	console.log("image slidersss start");
	console.log(assets);
	console.log(assets_val);
	console.log("image slidersss end"); 
	*/
	let img_url_temp='';
	let cusID='';
    return (
        <section className='slider'>
            <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide}  style={{zIndex:99999}} />
            <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} style={{zIndex:99999}} />
			{assets_val.map((asset_val1, index1) => { 
				 asset_val1.map((asset_val) => { 
					img_url_temp 	= 	asset_val.split(".com");
					img_url_temp 	= 	asset_val.split(".com");
					img_url_temp 	= 	img_url_temp[1].split("/"); 
					cusID			= 	img_url_temp[4];
					allow_ids.push(cusID);
					})
				})
			}
            {
				assets.map((asset, index) => {  
					final_arr[asset.id]=asset;
			}) }
			  	
				{assets_val[0].map((asset_val,index) => {  
					img_url_temp 	= 	asset_val.split(".com");
					img_url_temp 	= 	img_url_temp[1].split("/"); 
					cusID			= 	img_url_temp[4]; 
					console.log(index +"=="+ current);
					
                return (
                    <div testet={index +"="+ current}
                        className={index === current ? 'slide active' : 'slide'}
                        key={index}
                    >
                        {index === current && (
                            <div className='imageSlider' test={final_arr[cusID]['file_extension']}>
							{ final_arr[cusID]['file_extension'] == ".bmp" ||  final_arr[cusID]['file_extension'] == ".png" || final_arr[cusID]['file_extension'] == ".jpeg" || final_arr[cusID]['file_extension'] == ".jpg" ?
                                <img className={'image'} style={{ fontSize: 20, width: 'auto'}} src={ final_arr[cusID]['file_extension'] === ".bmp" ||     final_arr[cusID]['file_extension'] === ".png" || final_arr[cusID]['file_extension'] === ".jpeg" || final_arr[cusID]['file_extension'] === ".jpg" ? final_arr[cusID]['public_url'] : null} alt={ final_arr[cusID]['file_extension'] !== ".bmp" && final_arr[cusID]['file_extension'] !== ".png" && final_arr[cusID]['file_extension'] !== ".jpeg" && final_arr[cusID]['file_extension'] !== ".jpg" ? "Please click button below to download file" : "Unable to load content"} />  								
								: null}
							    <p style={{ textAlign: 'center' }}><b>File name:</b> {final_arr[cusID]['name']}</p>
                                <p style={{ textAlign: 'center' }}><b>Upload date:</b> {moment(final_arr[cusID]['created_at']).format("LLL")}</p> 
                                { final_arr[cusID]['file_extension'] !== ".bmp" && final_arr[cusID]['file_extension'] !== ".png" && final_arr[cusID]['file_extension'] !== ".jpeg" && final_arr[cusID]['file_extension'] !== ".jpg" ?
                                    <a style={{ fontSize: 20 }} href={final_arr[cusID]['public_url']} download>Download</a>
                                    : null}
                            </div>  
                        )}
                    </div>
                );
				})   
			}
        </section>
    );
};

export default ImageSlider;