import React from 'react'
import Alert from 'react-bootstrap/Alert';

const ForgottenPassword = (props) => {

    const { handlePasswordReset, setForgottenEmail, forgottenEmail, handleForgottenPasswordBack, submitted } = props

    return (
        <section>
            <div>
                <form>
                    <input placeholder="Email" value={forgottenEmail} type="text" name="email" onChange={(e) => setForgottenEmail(e.target.value)} /> <br/>
                    {submitted ?
                        <Alert variant="success">
                            Password reset email sent if your email exists.
                        </Alert>
                    : null}
                    <button className="btn btn-secondary" onClick={handleForgottenPasswordBack}>Back</button>
                    <button className="btn btn-primary" onClick={handlePasswordReset}>Reset Password</button>
                </form>
            </div>
        </section>
    )
}

export default ForgottenPassword;