import React, { useEffect, useState } from 'react'

const Views = ({ handleViewsBack, setView, type, liveWorksBoards, valveBoards, pipeWorkBoards, fabricationBoards, mechanicalBoards, grabsBoards, machiningBoards, valveSupplyBoards, sourcingBoards, setSelectedBoard, boards }) => {
    const [viewBoards, setViewBoards] = useState([])

    useEffect(() => {

        let boardIds = []

        if (type == "LiveWorks" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(liveWorksBoards)) {
                if (liveWorksBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] === true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Valves" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(valveBoards)) {
                if (valveBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] === true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Pipe_Work" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(pipeWorkBoards)) {
                if (pipeWorkBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] == true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Fabrication" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(fabricationBoards)) {
                if (fabricationBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] == true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Mechanical" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(mechanicalBoards)) {
                if (mechanicalBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] == true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Grabs" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(grabsBoards)) {
                if (grabsBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] == true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Machining" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(machiningBoards)) {
                if (machiningBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] == true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Valve_Supply" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(valveSupplyBoards)) {
                if (valveSupplyBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] == true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        else if (type == "Sourcing" && boards !== undefined && boards !== null) {
            for (var key of Object.keys(sourcingBoards)) {
                if (sourcingBoards[key] === true) {
                    for (var brdKey of Object.keys(boards)) {
                        if (boards !== undefined && boards !== null && boards[key] == true && brdKey === key) {
                            const count = Number(key)
                            boardIds.push(count)
                        }
                    }
                }
            }
        }
        GetGroups(boardIds)

    }, [])

    const GetGroups = (boardIds) => {
        let query = `{ 
            boards(ids: [${boardIds}]) {
                id
                name
              }
        }`;

        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': query
            })
        })
            .then(res => res.json())
            .then(res => {
                setViewBoards(res.data.boards)
            })
    }

    return (
        <section>
            <nav>
                <div className="Splash-Button-Div">
                    {viewBoards.map(brd => {
                        return <button key={brd.id} className="align-middle" style={{ height: 200, width: 200, margin: 40, borderRadius: 50 }} onClick={() => { setView('view'); setSelectedBoard(brd.id) }}>{brd.name}</button>
                    })}
                </div>
                <button className="btn btn-secondary" onClick={handleViewsBack}>Back</button>
            </nav>
        </section>
    )
}

export default Views;