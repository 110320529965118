import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-modal';
import { ClickAwayListener, withWidth } from '@material-ui/core';
import ImageSlider from './ImageSlider';

import { EditorState } from "draft-js";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../SlideDrawer.css'

import speechBubble from '../Assets/comment.png'
import blueSpeechBubble from '../Assets/newcomment.png'

import folderimage from '../Assets/folder-icon.png'; 

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%', 
		overflow : 'hidden',
		position: 'fixed',
		zIndex: '9999'
		
    }
};

const Board = ({ handleBoardBack, view, type, title, setTitle, columnValues, setColumnValues, fire, selectedBoard, setSelectedBoard, account }) => {
    // const [data, setData] = useState([])
    // const [chosenItems, setChosenItems] = useState([])
    const [rowId, setRowId] = useState('')
    const [updates, setUpdates] = useState(undefined)
    const [update, setUpdate] = useState('')
    const [uid, setUid] = useState('')
    const [name, setName] = useState('')
    const [site, setSite] = useState('')
    const [sortedItems, setSortedItems] = useState([])
    const [chosenFields, setChosenFields] = useState([])
    const [chosenSites, setChosenSites] = useState([])
    const [chosenStages, setChosenStages] = useState([])
    const [chosenRow, setChosenRow] = useState(undefined)
    const [orderedColumnHeaders, setOrderedColumnHeaders] = useState([])
    const [assets, setAssets] = useState(undefined)
	const [assets_val, setAssets_val] = useState(undefined)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [titleToggle, setTitleToggle] = useState(false)
    const [sortConfig, setSortConfig] = useState({ key: undefined, direction: undefined })
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [boardError, setBoardError] = useState('')


    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    // useEffect(() => {
    //     // console.log(editorState);
    // }, [editorState]);

    React.useMemo(() => {
        let sortedData = [...sortedItems];
        if (sortConfig !== null) {
            if (sortConfig.key != "name") {
                sortedData.sort((a, b) => {
                    if (a.column_values[sortConfig.key] < b.column_values[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a.column_values[sortConfig.key] > b.column_values[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }
            else {
                sortedData.sort((a, b) => {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }

        }
        return sortedData;
    }, [sortedItems, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    let createUpdateQuery = `mutation {
        create_update(item_id: ${rowId}, body: "Update by ${name} from the ${site} site: ${update}") {
            id
        }
    }`

    let createReplyQuery = `mutation {
        create_update(item_id: 1055212127, body: "example body", parent_id: 942424998) {
            id
        }
    }`

    const CreateUpdate = () => {
        if (update === '') {
            setError("Please enter an update.")
        }
        else {
            fetch("https://api.monday.com/v2", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
                },
                body: JSON.stringify({
                    'query': createUpdateQuery
                })
            })
                .then(res => res.json())

            setUpdate('')
            setError('')
            GetUpdates(rowId)
        }
    }

    const GetUpdates = (id) => {
        //if(id !== undefined)
            setRowId(id)

        let updatesQuery = `
        {
            boards(ids: ${selectedBoard}) {
                items(ids: ${id}) {
                    updates {
                        id
                        text_body
                        created_at
                        creator {
                            name
                            created_at
                        }
                        replies {
                            text_body
                            created_at
                            creator {
                            name
                            created_at
                            }
                        }
                    }
                }
            }
        }`;

        if (id !== '') {
            fetch("https://api.monday.com/v2", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
                },
                body: JSON.stringify({
                    'query': updatesQuery
                })
            })
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    setUpdates((res.data.boards[0].items[0].updates))
                    console.log( "setting update")
                    console.log(res.data.boards[0].items[0].updates)
                    sortedItems.map(item => {
                        if (item.id === id) {
                            setChosenRow(item)
                        }
                    })
                })
                .catch(err => console.error(err))
        }
        else {
            console.log("Please select a row")
        }
    }

    const GetAssets = (e, group,value) => {
        // console.log("e = ", e.target.parentElement.parentElement)
		if(value != ""){
			console.log("value==");
			console.log(value[0]);
					/* fileCount= value[0].length;  
					fileURL = value[0][0] ;
					let nextURL	    = 	value[0][0]; 
					const file_ext = [".png",".jpg",".jpeg",".bmp" ,".giff",".PNG",".JPG",".JPEG",".BMP" ,".GIFF"];
					is_file = false;  
					{file_ext.map(file_ext_single=>{ 
						if(nextURL.search(file_ext_single)>0){
							is_file = true;  
						} 
					})} */
				/* 
					{obj.assets.map(qwert_img_val=>{
						let img_url_temp 	= 	qwert_img_val.split(".com");
						img_url_temp 	 	= 	img_url_temp[1].split("/");
						//	console.log(img_url_temp);
						let newImgArr   	= 	[];
						let cusID			= 	img_url_temp[3];
						newImgArr[cusID]	=	qwert_img_val;  
						if(nextURL.search(cusID)>0){ 
							fileURL = newImgArr[cusID] ;
						} 
					})} */
		}
        let parentRow = e.target.parentElement.parentElement
        for (var parent in parentRow) {
            if (parentRow[parent] != null || parentRow[parent] != undefined) {
                if (parentRow[parent].key != null || parentRow[parent].key != undefined) {
                    var id = parentRow[parent].key
                    setRowId(id)
                }
            }
        }

        let assetQuery = `{
            boards(ids: ${selectedBoard}) {
                groups(ids: "${group}") {
                    items(ids: ${id}) {
                        name
                        id
                        assets {
                            file_extension
                            url
							url_thumbnail
							public_url
                            id
                            file_size
                            created_at
                            name
                            uploaded_by {
                                name
                            }
                        }
                    }
                }
            }
        }`;

        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': assetQuery
            })
        })
            .then(res => res.json())
            .then(res => {
				/* console.log("Assets");
				console.log(res.data); */
				if(res.data !== undefined){
					setAssets(res.data.boards[0].groups[0].items[0].assets)
					setAssets_val(value) 
					
					sortedItems.map(item => {
						if (item.id === id) {
							setChosenRow(item)
						}
					})
				}
            });
    }
    //Get the board
    const GetBoard = () => {
        var chsnFields = []
        let groupArray = []
        let chsnSites = []
        let chsnStages = []
        let chsnBrd = ''

        setLoading(true)

        fire.auth().onAuthStateChanged((user) => {
            if (account) {
                setUid(user.uid)

                if (type === "LiveWorks") {
                    fire.database()
                        .ref(`/Boards/${account}/${user.uid}`)
                        .once('value')
                        .then(snapshot => {
                            var data = snapshot.val()
                            // console.log(data.Valves)
                            if (data != null) {
                                for (var key of Object.keys(data)) {
                                    if (key === "LiveWorks") {
                                        for (var liveWorksKey of Object.keys(data[key])) {
                                            if (data[key][liveWorksKey] === true) {
                                                setSelectedBoard(liveWorksKey)
                                                chsnBrd = liveWorksKey
                                            }
                                        }
                                    }
                                }
                            }
                        })
                }

                fire.database()
                    .ref(`/Fields/${account}/${selectedBoard}/${user.uid}`)
                    .once('value')
                    .then(snapshot => {
                        var data = snapshot.val()
                        if (data != null || data != undefined) {
                            Object.entries(data).map(([key, value]) => {
                                if (value.toString() == "true") {
                                    if (key == "Title") {
                                        setTitleToggle(key)
                                    }
                                    chsnFields.push(key)
                                }
                            })
                        }
                        setChosenFields(chsnFields)
                    });


                fire.database()
                    .ref(`/Sites/${account}/${selectedBoard}/${user.uid}/`)
                    .once('value')
                    .then(siteSnapshot => {
                        var siteData = siteSnapshot.val()
                        //console.log(siteData);
                        if (siteData !== null && siteData !== undefined) {
                            Object.entries(siteData).map(([siteKey, siteValue]) => {
                                if (siteValue.toString() == "true") {
                                    //console.log("siteKey = ", siteKey)
                                    chsnSites.push(siteKey)
                                }
                            })
                        }
                        else {
                            chsnSites = null
                        }
                        setChosenSites(chsnSites)
                    });

                fire.database()
                    .ref(`/Stages/${account}/${selectedBoard}/${user.uid}/`)
                    .once('value')
                    .then(stageSnapshot => {
                        var stageData = stageSnapshot.val()
                        if (stageData !== null && stageData !== undefined) {
                            Object.entries(stageData).map(([stageKey, stageValue]) => {
                                if (stageValue.toString() == "true") {
                                    console.log("stageKey = ", stageKey)
                                    console.log("stageValue = ", stageValue)

                                    chsnStages.push(stageKey)
                                }
                            })
                        }
                        else {
                            chsnStages = null
                        }
                        setChosenStages(chsnStages)
                    });

                fire.database()
                    .ref(`/Clients/${account}/${user.uid}`)
                    .once('value')
                    .then(snapshot => {
                        var data = snapshot.val()
                        if (data != null || data != undefined) {
                            Object.entries(data).map(([key, value]) => {
                                if (key == 'name') {
                                    setName(value)
                                }
                                else if (key == 'site') {
                                    setSite(value)
                                }
                            })
                        }
                    });
            } else {
                // User is signed out
            }
        });

        if (chsnBrd === '') {
            chsnBrd = selectedBoard
        }

        let groupQuery = `{
            boards(ids: ${chsnBrd}) {
                id
                name
                groups {
                  id
                  title
                }
              }
        }`;

        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': groupQuery
            })
        })
            .then(res => res.json())
            .then(res => {
                console.log("doing board query")
                console.log(res);
                res.data.boards[0].groups.map(group => {
                    groupArray.push(group.id)
                })
                let boardQuery = `{
                    boards(ids: ${chsnBrd}) {
                        name
                        groups(ids: [${groupArray.join()}]) {
                            title
                            id
                            items {
                                name
                                id
                                updates {
                                    id
                                    text_body
                                    item_id
                                    created_at
                                }
                                column_values {
                                    id
                                    title
                                    value
                                    additional_info
                                    type
                                    text
                                }
                                assets{ 
                                    public_url
                                }
                            }
                        }
                    }
                }`;

                fetch("https://api.monday.com/v2", {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
                    },
                    body: JSON.stringify({
                        'query': boardQuery
                    })
                })
                    .then(res => res.json())
                    .then((res) => {
                        //console.log(res);
                        console.log("sorting items");
                        var finalItems = []
                        var ordColHeaders = []
                        let siteSortedData = []
                        let stageSortedData = []

                        let finalSortedData = null

                        setTitle(res.data.boards[0].name)

                        if (chsnSites === null || chsnSites.length === 0) {
                            chsnSites = null
                        }
                        if (chsnStages === null || chsnStages.length === 0) {
                            chsnStages = null
                        }

                        if (sortedItems) {
                            if (chsnSites !== null && chsnSites !== undefined && chsnSites !== []) {
                                res.data.boards[0].groups.map(group => {
                                    group.items.map(item => {
                                        item.column_values.map(colVal => {
                                            if (colVal.title === "SiteName" && colVal.text !== "" && colVal.text !== null && chsnSites.includes(colVal.text)) {
                                                siteSortedData.push({ item: item, group: group.id })
                                            }
                                        })
                                    })
                                })
                                finalSortedData = siteSortedData
                            }

                            if (type === "LiveWorks" && finalSortedData !== null) {
                                res.data.boards[0].groups.map(group => {
                                    group.items.map(item => {
                                        item.column_values.map(colVal => {
                                            if (colVal.title === "Stage" && colVal.text === "Live Works") {
                                                siteSortedData.map((data) => {
                                                    data.item.column_values.map((column) => {
                                                        if (column.title === "Stage" && column.text === "Live Works" && !stageSortedData.includes(data)) {
                                                            stageSortedData.push(data)
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    })
                                })
                                finalSortedData = stageSortedData
                            }
                            else if (chsnStages !== null && chsnStages !== undefined && chsnStages !== [] && finalSortedData !== null) {
                                res.data.boards[0].groups.map(group => {
                                    group.items.map(item => {
                                        item.column_values.map(colVal => {
                                            if (colVal.title === "Stage" && colVal.text === "Live Works") {
                                                siteSortedData.map((data) => {
                                                    data.item.column_values.map((column) => {
                                                        if (column.title === "Stage" && column.text === "Live Works" && !stageSortedData.includes(data)) {
                                                            stageSortedData.push(data)
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    })
                                })
                                finalSortedData = stageSortedData
                            }
                            else if (chsnStages !== null && chsnStages !== undefined && chsnStages !== []) {
                                res.data.boards[0].groups.map(group => {
                                    group.items.map(item => {
                                        item.column_values.map(colVal => {
                                            if (colVal.title === "Stage" && colVal.text !== "" && colVal.text !== null && chsnStages.includes(colVal.text)) {
                                                stageSortedData.push({ item: item, group: group.id })
                                            }
                                        })
                                    })
                                })
                                finalSortedData = stageSortedData
                            }

                            // console.log("finalSortedData 1 = ", finalSortedData)
                            if (finalSortedData !== null && finalSortedData !== undefined) {
                                // console.log("finalSortedData 2 = ", finalSortedData)
                                console.log("here");

                                // siteSortedData.map(group => {
                                finalSortedData.map(item => {
                                    var columnValues = []
                                    // let numberOfUpdates = 0

                                    // item.updates.map(update => {
                                    //     if (item.id === update.item_id) {
                                    //         numberOfUpdates++
                                    //     }
                                    // })
                                    // if (item.updates[0] !== null && item.updates[0] !== undefined) {
                                    //     if (!item.updates[0].text_body.includes("site:")) {
                                    //         numberOfUpdates = numberOfUpdates + "(New)"
                                    //     }
                                    // }

                                    if (item.item.updates[0] !== null && item.item.updates[0] !== undefined) {
                                        const startDate = moment(item.item.updates[0].created_at);
                                        const timeEnd = moment(Date());
                                        const diff = timeEnd.diff(startDate);
                                        const diffDuration = moment.duration(diff);

                                        if (diffDuration.days() <= 7) {
                                            columnValues.push({ Comments: blueSpeechBubble })
                                        }
                                        else {
                                            columnValues.push({ Comments: speechBubble })
                                        }
                                    }
                                    else {
                                        columnValues.push({ Comments: speechBubble })
                                    }

                                    item.item.column_values.map(colVal => {
                                        if (chsnFields.includes(colVal.id)) {
                                            if (!ordColHeaders.includes(colVal.title)) {
                                                ordColHeaders.push(colVal.title)
                                            }
                                            if (colVal.value !== null && colVal.value.includes("{")) {
                                                if (colVal.type == "file") {
                                                    let splitValue = colVal.value.split("},")
                                                    columnValues.push({ [colVal.title]: "Files" }) //("+splitValue.length+")"
                                                }
                                                else if (colVal.additional_info !== null) {
                                                    var additionalValuesSplit = colVal.additional_info.split('"');
                                                    columnValues.push({ [colVal.title]: additionalValuesSplit[3] })
                                                }
                                                else if(colVal.text !== null){
                                                    var textVal = colVal.text;
                                                    columnValues.push({ [colVal.title]: textVal})
                                                }
                                            }
                                            else {
                                                if (colVal.value !== null) {
                                                    var valuesSplit = colVal.value.split('"');
                                                    columnValues.push({ [colVal.title]: valuesSplit[1] })
                                                }
                                                else {
                                                    columnValues.push({ [colVal.title]: colVal.text })
                                                }
                                            }
                                        }
                                    })
                                    // console.log("finalItems 1 = " + finalItems)
                                    finalItems.push({ id: item.item.id, name: item.item.name, group: item.group, column_values: columnValues })
                                })
                                // })
                            }
                            else {
                                // console.log("finalSortedData 3 = ", finalSortedData)

                                res.data.boards[0].groups.map(group => {
                                    console.log("here");
                                    group.items.map(item => {
                                        var columnValues = []
                                        // let numberOfUpdates = 0

                                        // item.updates.map(update => {
                                        //     if (item.id === update.item_id) {
                                        //         numberOfUpdates++
                                        //     }
                                        // })
                                        // if (item.updates[0] !== null && item.updates[0] !== undefined) {
                                        //     if (!item.updates[0].text_body.includes("site:")) {
                                        //         numberOfUpdates = numberOfUpdates + "(New)"
                                        //     }
                                        // }

                                        if (item.updates[0] !== null && item.updates[0] !== undefined) {
                                            const startDate = moment(item.updates[0].created_at);
                                            const timeEnd = moment(Date());
                                            const diff = timeEnd.diff(startDate);
                                            const diffDuration = moment.duration(diff);

                                            if (diffDuration.days() <= 7) {
                                                columnValues.push({ Comments: blueSpeechBubble })
                                            }
                                            else {
                                                columnValues.push({ Comments: speechBubble })
                                            }
                                        }
                                        else {
                                            columnValues.push({ Comments: speechBubble })
                                        } 
                                        let qwert_img =[];
                                        item.assets.map(val11 => {  
                                            qwert_img.push(val11.public_url); 
                                        }) 
                                        item.column_values.map(colVal => {
                                            if (chsnFields.includes(colVal.id)) {
                                                if (!ordColHeaders.includes(colVal.title)) {
                                                    ordColHeaders.push(colVal.title)
                                                }
                                                if (colVal.value !== null && colVal.value.includes("{")) {
                                                    if (colVal.type == "file") {

                                                        // console.log("hhh"  )
                                                        // console.log("colVal = ", colVal) 

                                                        let splitLinks = colVal.text.split(", ")
                                                        //  console.log("splitLinks = ", splitLinks)

                                                        // let splitValue = colVal.value.split("},")
                                                       /// columnValues.push({ Links_new: [qwert_img] });
                                                        columnValues.push({ Links: [splitLinks] }) 
                                                        //("+splitValue.length+")"
                                                        //Qwert magic 
                                                    } else {
                                                        if (colVal.text !== null) {
                                                            columnValues.push({ [colVal.title]: colVal.text })
                                                        }
                                                        else {
                                                            var additionalValuesSplit = colVal.additional_info.split('"');
                                                            columnValues.push({ [colVal.title]: additionalValuesSplit[3] })
                                                        }
                                                    }

                                                }
                                                else {
                                                    if (colVal.value !== null) {
                                                        var valuesSplit = colVal.value.split('"');
                                                        columnValues.push({ [colVal.title]: valuesSplit[1] })
                                                    }
                                                    else {
                                                        columnValues.push({ [colVal.title]: colVal.text })
                                                    }
                                                }
                                            }
                                        })
                                        finalItems.push({ id: item.id, name: item.name, group: group.id, column_values: columnValues,assets:qwert_img })
                                    })
                                })
                            }
                            // else {
                            //     // console.log("finalSortedData 4 = ", finalSortedData)

                            //     setBoardError("Please contact IME to setup your board")
                            // }
                        }

                        // finalItems.map(item => {
                        //     item.column_values.map(col => Object.entries(col).map(([key, value]) => {
                        //         if (key === "links") {
                        //             for (let i = 0; i < value[0].length; i++) {
                        //                 if (value[0][i] !== "") {
                        //                     console.log("value[i] = ", value[0][i])
                        //                 }
                        //             }
                        //         }
                        //     }))
                        // })

                        // console.log("finalItems = ", finalItems)


                        let sortedFinalItems = [...finalItems];
                        sortedFinalItems.sort((a, b) => {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        });

                        setOrderedColumnHeaders(ordColHeaders)
                        setSortedItems(finalItems)
                        setLoading(false)
                        console.log("gotten board");
                    })
                    .catch(err => {
                        console.log("err 1 = ", err)
                    })
            })
            .catch(err => {
                console.log("err 2 = ", err)
            })
    }

    useEffect(() => {
        GetBoard()
    }, [])

    var tableData = sortedItems.map(function (obj) {
		let fileURL = ''; 
		let fileCount = 0; 
		let is_file = false ;
        return <tr style={{ backgroundColor: obj.id == rowId ? "#00FFFF" : "white" }} className="tb" key={obj.id}>
            <td style={{ width: "15%" }} key={obj.id}>{obj.name}</td>  
            {obj.column_values.map(item => Object.entries(item).map(([key, value]) => {
				if(key === "Links" && value !=''){ 
					fileCount= value[0].length;  
					fileURL = value[0][0] ;
					let nextURL	    = 	value[0][0];
					/* console.log("nextURL");
					console.log(nextURL); */
					const file_ext = [".png",".jpg",".jpeg",".bmp" ,".giff",".PNG",".JPG",".JPEG",".BMP" ,".GIFF"];
					is_file = false;  
					{file_ext.map(file_ext_single=>{ 
						if(nextURL.search(file_ext_single)>0){
							is_file = true;  
						} 
					})}
				
					{obj.assets.map(qwert_img_val=>{
						let img_url_temp 	= 	qwert_img_val.split(".com");
						img_url_temp 	 	= 	img_url_temp[1].split("/");
						//	console.log(img_url_temp);
						let newImgArr   	= 	[];
						let cusID			= 	img_url_temp[3];
						newImgArr[cusID]	=	qwert_img_val;  
						if(nextURL.search(cusID)>0){ 
							fileURL = newImgArr[cusID] ;
						} 
					})}
				}   
				
                return key === "Links" && value !='' ? 
					is_file === true ? 
					<td style={{ color: "blue" }} className="text-decoration-underline" value={value} onClick={(e) => GetAssets(e, obj.group,value)}>
					    <img keysdasda ={key} src={fileURL} width="25" height="25" alt="Files" />
						<br /><p style={{ textDecoration: 'none' }} className="pointer">Total files: {fileCount }</p>						
                    </td> :
					<td style={{ color: "blue" }} className="text-decoration-underline" value={value} onClick={(e) => GetAssets(e, obj.group,value)}><img src={folderimage} alt="folder" width="25" height="25" /> <br /><p style={{ textDecoration: 'none' }} className="pointer">Total files: {fileCount }</p></td>
                    :
                    key === "Comments" ?
                        <td onClick={() => GetUpdates(obj.id)}>
                            <img src={value} onClick={() => { GetUpdates(obj.id)}} width="25" height="25" alt="Comments" />
                        </td>
                        :
                        <td><div className="text">{value}</div><div className="tooltip">{value}</div></td>
            }
            ))}</tr>
    })

    var titleLessTableData = sortedItems.map(function (obj) {
        // console.log('Dinesh');
        // console.log(JSON.stringify(obj.column_values));
        return <tr style={{ backgroundColor: obj.id == rowId ? "#00FFFF" : "white" }} className="tb" key={obj.id}>
            {obj.column_values.map(item => Object.entries(item).map(([key, value]) => {
                return key === "Links" ?
                    <td style={{ color: "blue" }} className="text-decoration-underline" onClick={(e) => GetAssets(e, obj.group,"")} >
                        <img src={value[0][0]} width="25" height="25" alt="Files" />
                    </td>
                    :
                    key === "Comments" ?
                        <td onClick={() => GetUpdates(obj.id)}>
                            <img src={value} onClick={() => GetUpdates(obj.id)} width="25" height="25" alt="Comments" />
                        </td>
                        :
                        <td>{value}</td>
            }
            ))}</tr>
    })

    // const CloseImageViewer = () => {
    //     setAssets(undefined)
    //     setRowId(undefined)
    // }

    const CloseDrawer = () => {
        setUpdates(undefined)
        setRowId(undefined)
        setError('')
    }

    const CloseImageDrawer = () => {
        setAssets(undefined)
		setAssets_val(undefined)
        setRowId(undefined)
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
            * Alert if clicked on outside of element
            */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    CloseDrawer()
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    // const handleClick = e => {

    //     if (drawerRef.current !== undefined && drawerRef.current !== null) {
    //         if (drawerRef.current.contains(e.target)) {
    //             // inside click
    //             return;
    //         }
    //     }
    //     // outside click
    //     CloseImageDrawer();
    // };

    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClick);

    //     return () => {
    //         document.removeEventListener("mousedown", handleClick);
    //     };
    // }, []);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    return (
        <section style={{ width: "90%" }}>
            <nav>
                {title}
                {loading ? (
                    <h3>Loading...</h3>
                ) : boardError !== '' ? (
                    <p style={{ color: "red" }}>{boardError}</p>
                ) : (
                    <div>
                        <div className="table-wrapper" style={{ marginTop: 20, overflowX: "scroll"}}>
                            <table style={{ fontSize: 14 }} className="table table-hover table-responsive-custom">

                                    <tr>
                                        {titleToggle ? <th key="title"><b>Title</b></th> : null} {/* <button type="button" onClick={() => requestSort("name")}>^</button> */}
                                        <th key="Comments"><b>Comments</b></th>
                                        {orderedColumnHeaders.map(ch => <th key={ch}><b>{ch}</b></th>)} {/* <button type="button" onClick={() => requestSort(ch)}>^</button> */}
                                    </tr>
                                <tbody>
                                    {titleToggle ? tableData : titleLessTableData}
                                </tbody>
                            </table>
                        </div>

                    </div>
                )}


                {assets ?
                    <Modal isOpen={assets} style={customStyles}>
                        <div style={{ fontSize: 14, zIndex:9999 }}>
                            <div style={{ display: "flex" }}>
                                <button className="btn btn-secondary" onClick={CloseImageDrawer}>X</button>
                                 
                            </div>
                            <br />

                            <ImageSlider assets={assets} assets_val={assets_val} />

                            <br />

                            {chosenRow ?
                                <div style={{ textAlign: "left", marginLeft: 20, border: "1px solid", marginRight: 20, marginTop: 250 }}>
                                    <div style={{ marginLeft: 10 }}>
                                        <h5>Selected row details</h5>
                                        <p style={{ margin: 0 }}>Title: {chosenRow.name}</p>
                                        {chosenRow.column_values.map(colVal => Object.entries(colVal).map(([key, value]) => {
                                            return key === "Comments" ? null : key === "Links" ? null : value ? <p style={{ margin: 0 }}>{key}: {value}</p> : null
                                        }))}
                                    </div>
                                </div>
                                : null}

                            {/* <Carousel showArrows={true} dynamicHeight={false} infiniteLoop={true} showThumbs={false} useKeyboardArrows={true}>
                                    {assets.map(asset =>
                                        <div>
                                            <div>
                                                <h2>{asset.name}</h2>
                                            </div>
                                            <br />
                                            <div>
                                                <img style={{ fontSize: 20 }} src={asset.file_extension === ".png" || asset.file_extension === ".jpeg" ? asset.url : null} alt={asset.file_extension !== ".png" && asset.file_extension !== ".jpeg" ? "Please click button below to download file" : "Unable to load content"} />
                                                {asset.file_extension === ".png" || asset.file_extension === ".jpeg" ?
                                                    <div style={{ fontSize: 18 }}>
                                                        <p><b>File name:</b> {asset.name}</p>
                                                        <p><b>File Type:</b> {asset.file_extension === ".png" || asset.file_extension === ".jpeg" ? "Image" : "File"}</p>
                                                        <p><b>Upload date:</b> {moment(asset.created_at).format("LLL")}</p>
                                                        <p><b>Size:</b> {asset.file_size} bytes</p>
                                                        <p> <b>Uploaded by:</b> {asset.uploaded_by.name} </p>
                                                    </div>
                                                    : null}
                                            </div>
                                            <br />
                                            {asset.file_extension !== ".png" && asset.file_extension !== ".jpeg" ?
                                                <a style={{ fontSize: 20 }} href={asset.url} download>Download</a>
                                                : null}
                                        </div>
                                    )}
                                </Carousel> */}
                        </div>
                    </Modal>
                    : null}
                <br />
                <button className="btn btn-secondary" onClick={handleBoardBack}>Back</button>
                <br />
                { updates ? (
                    // <ClickAwayListener onClickAway={CloseDrawer}>
                        <div style={{ fontSize: 14 }} ref={wrapperRef} className="side-drawer open drawer-nav">
                            <div style={{ display: "flex" }}>
                                <button className="btn btn-secondary" onClick={CloseDrawer}>X</button>
                                <h1 style={{ marginLeft: "35%" }}>Comments</h1>
                            </div>
                            <br />
                            {chosenRow ?
                                <div style={{ textAlign: "left", marginLeft: 20, border: "1px solid", marginRight: 20 }}>
                                    <div style={{ marginLeft: 10 }}>
                                        <h5>Selected row details</h5>
                                        <p style={{ margin: 0 }}>Title: {chosenRow.name}</p>
                                        {chosenRow.column_values.map(colVal => Object.entries(colVal).map(([key, value]) => {
                                            return key === "Comments" ? null : key === "Links" ? null : value ? <p style={{ margin: 0 }}>{key}: {value}</p> : null
                                        }))}
                                    </div>
                                </div>
                                : null}
                            <br />
                            {updates.map(obj =>
                                <div>
                                    {obj.text_body.includes("site:") ?
                                        <p style={{ marginRight: 20, marginLeft: "20%", border: "1px solid", borderRadius: 10, backgroundColor: "#89E0FF" }}>
                                            <p style={{ textAlign: "left", marginLeft: 10, fontSize: 12 }} key={obj.id}>{obj.text_body}</p>
                                            <br />
                                            <p style={{ textAlign: "right", marginRight: 10, fontSize: 10, marginBottom: 0 }}>{moment(obj.created_at).format("LLL")}</p>
                                        </p>
                                        :
                                        <p style={{ marginLeft: 20, border: "1px solid", marginRight: "20%", borderRadius: 10, backgroundColor: "#A7FBA9" }}>
                                            <p style={{ textAlign: "left", marginLeft: 10, fontSize: 12 }} key={obj.id}>{obj.text_body}</p>
                                            <br />
                                            <p style={{ textAlign: "right", marginRight: 10, fontSize: 10, marginBottom: 0 }}>{moment(obj.created_at).format("LLL")}</p>
                                        </p>
                                    }
                                    {obj.replies.map(reply =>
                                        <div>
                                            {reply.text_body.includes("site:") ?
                                                <p style={{ marginRight: 20, marginLeft: "20%", border: "1px solid", borderRadius: 10, backgroundColor: "#89E0FF" }}>
                                                    <p style={{ textAlign: "left", marginLeft: 10, fontSize: 12 }}>{reply.text_body}</p>
                                                    <br />
                                                    <p style={{ textAlign: "right", marginRight: 10, fontSize: 10, marginBottom: 0 }}>{reply.creator.name} - {moment(reply.created_at).format("LLL")}</p>
                                                </p>
                                                :
                                                <p style={{ marginLeft: 20, border: "1px solid", marginRight: "20%", borderRadius: 10, backgroundColor: "#A7FBA9" }}>
                                                    <p style={{ textAlign: "left", marginLeft: 10, fontSize: 12 }}>{reply.text_body}</p>
                                                    <br />
                                                    <p style={{ textAlign: "right", marginRight: 10, fontSize: 10, marginBottom: 0 }}>{reply.creator.name} - {moment(reply.created_at).format("LLL")}</p>
                                                </p>
                                            }
                                        </div>
                                    )}
                                </div>)}
                            <br />
                            <div>
                                <textarea onChange={(e) => setUpdate(e.target.value)} value={update} />
                            </div>
                            <p style={{ color: "red" }}>{error}</p>
                            <br />
                            <button className="btn btn-primary" onClick={CreateUpdate}>Create Update</button>
                        </div>
                    // </ClickAwayListener>
                ) : (
                    <div />
                )}
                <br />
                <br />
            </nav>
        </section>
    )
}

export default Board;