import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert';

const SuperAdminArea = ({ fire, handleSuperAdminBack }) => {
    const [adminList, setAdminList] = useState([])
    const [superAdminBoards, setSuperAdminBoards] = useState([])
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState('');
    const [adminBoards, setAdminBoards] = useState([])
    const [users, setUsers] = useState([])
    const [allUsers, setAllUsers] = useState([])

    let query = `{ 
        boards(limit: 200) 
        {
            name 
            id
            board_kind
        } 
    }`;

    useEffect(() => {
        var accLoopData = []
        var clientLoopData = []

        fire.database()
            .ref(`/Accounts`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                for (var key of Object.keys(data)) {
                    if (data[key].admin === true) {
                        accLoopData.push({ id: key, company: data[key].company })
                    }

                }
            });

        fire.database()
            .ref(`/Clients`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                for (var key of Object.keys(data)) {
                    for (var clientKey of Object.keys(data[key])) {
                        for (var accKey of Object.keys(accLoopData)) {
                            if (clientKey === accLoopData[accKey].id) {
                                clientLoopData.push({ id: clientKey, name: data[key][clientKey].name })
                            }
                        }
                    }
                }
                setAdminList(clientLoopData)
            });

        setAdminBoards([])

        let dbBoards = null
        let toggledBoards = []

        fire.database()
            .ref(`/AdminBoards/`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                if (data !== null) {
                    dbBoards = data
                    setAdminBoards(data)
                }
            });

        var usersLoopData = []

        fire.database()
            .ref(`/Clients/`)
            .once('value')
            .then(snapshot => {
                var allData = snapshot.val()

                for (var allKey of Object.keys(allData)) {
                    for (var clientKey of Object.keys(allData[allKey])) {
                        if (clientKey !== "logo") {
                            if (allData[allKey][clientKey].deleted === true) {
                                usersLoopData.push({ id: clientKey, active: allData[allKey][clientKey].active, name: allData[allKey][clientKey].name, email: allData[allKey][clientKey].email, site: allData[allKey][clientKey].site, company: allKey })
                            }
                        }
                    }
                }
                setAllUsers(usersLoopData)
            });


        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': query
            })
        })
            .then(res => res.json())
            .then(res => {
                let sortedBoards = res.data.boards.sort(compare);

                for (var key of Object.keys(sortedBoards)) {
                    // console.log("sortedBoards[key].name = ", sortedBoards[key].name)
                    if (dbBoards !== null) {
                        if (!toggledBoards.includes(sortedBoards[key].id)) {
                            toggledBoards.push({ id: sortedBoards[key].id, name: sortedBoards[key].name, toggle: dbBoards[sortedBoards[key].id] !== undefined ? dbBoards[sortedBoards[key].id] : false, board_kind: sortedBoards[key].board_kind })
                        }
                        else {
                            toggledBoards.push({ id: sortedBoards[key].id, name: sortedBoards[key].name, toggle: false, board_kind: sortedBoards[key].board_kind })
                        }
                    }
                    else {
                        toggledBoards.push({ id: sortedBoards[key].id, name: sortedBoards[key].name, toggle: false, board_kind: sortedBoards[key].board_kind })
                    }
                }
                setSuperAdminBoards(toggledBoards)
            })

        GetUsers()
    }, []);

    function compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    const UpdateSuperAdminDatabaseBoards = (e) => {
        e.preventDefault();
        setShowSuccess(true)

        const fieldsRef = fire.database().ref(`AdminBoards/${selectedAdmin}/`)

        fieldsRef.set(adminBoards);

        setTimeout(function () {
            setShowSuccess(false)

        }, 3000);
    };

    const UpdateAdminBoards = (e) => {
        var newEntry = Object.assign({}, adminBoards, ({ [e.target.value]: e.target.checked }));
        setAdminBoards(newEntry);
    }

    const GetUsers = () => {
        var accLoopData = []
        var clientLoopData = []

        fire.database()
            .ref(`/Accounts`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                for (var key of Object.keys(data)) {
                    if (data[key].company === "IME") {
                        accLoopData.push({ id: key, company: data[key].company, admin: data[key].admin })
                    }
                }
            });

        fire.database()
            .ref(`/Clients`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                for (var key of Object.keys(data)) {
                    for (var clientKey of Object.keys(data[key])) {
                        for (var accKey of Object.keys(accLoopData)) {
                            if (clientKey === accLoopData[accKey].id) {
                                clientLoopData.push({ id: clientKey, name: data[key][clientKey].name, toggle: accLoopData[accKey].admin, site: data[key][clientKey].site, email: data[key][clientKey].email })
                            }
                        }
                    }
                }
                setUsers(clientLoopData)
            });
    }

    const UpdateAdmin = (e) => {
        const accountsRef = fire.database().ref(`Accounts/${e.target.value}/`)

        accountsRef.update({ admin: e.target.checked });

        var accLoopData = []
        var clientLoopData = []

        fire.database()
            .ref(`/Accounts`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                for (var key of Object.keys(data)) {
                    if (data[key].admin === true) {
                        accLoopData.push({ id: key, company: data[key].company })
                    }

                }
            });

        fire.database()
            .ref(`/Clients`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                for (var key of Object.keys(data)) {
                    for (var clientKey of Object.keys(data[key])) {
                        for (var accKey of Object.keys(accLoopData)) {
                            if (clientKey === accLoopData[accKey].id) {
                                clientLoopData.push({ id: clientKey, name: data[key][clientKey].name })
                            }
                        }
                    }
                }
                setAdminList(clientLoopData)
            });
    }

    const RestoreListUser = (e, user) => {
        e.preventDefault();

        const clientRef = fire.database().ref(`Clients/${user.company}/${user.id}/`)

        clientRef.update({ deleted: false, active: true });


        var usersLoopData = []

        fire.database()
            .ref(`/Clients/`)
            .once('value')
            .then(snapshot => {
                var allData = snapshot.val()

                for (var allKey of Object.keys(allData)) {
                    for (var clientKey of Object.keys(allData[allKey])) {
                        if (clientKey !== "logo") {
                            if (allData[allKey][clientKey].deleted === true) {
                                usersLoopData.push({ id: clientKey, active: allData[allKey][clientKey].active, name: allData[allKey][clientKey].name, email: allData[allKey][clientKey].email, site: allData[allKey][clientKey].site, company: allKey })
                            }
                        }
                    }
                }
                setAllUsers(usersLoopData)
            });

    }

    return (
        <section>
            <div>
                <h3>Admin Users</h3>

                <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                    <thead className="table-light">
                        <tr>
                            <td>List of Boards</td>
                            <td>Activate / Deactivate</td>
                            <td>Type</td>
                        </tr>
                    </thead>
                    <tbody>
                        {superAdminBoards.map((brd) =>
                            <tr key={brd.id}>
                                <td>{brd.name}</td>
                                <td>
                                    <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                        <input onChange={(e) => UpdateAdminBoards(e)} type="checkbox" key={brd.id} value={brd.id} defaultChecked={brd.toggle} className="form-check-input" />
                                    </div>
                                </td>
                                <td>{brd.board_kind}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <h3>IME Users</h3>
                <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                    <thead className="table-light">
                        <tr>
                            <td>Name</td>
                            <td>Admin</td>
                            <td>Email</td>
                            <td>Site</td>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) =>
                            <tr>
                                <td>{user.name}</td>
                                <td>
                                    <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                        <input value={user.id} type="checkbox" onChange={(e) => UpdateAdmin(e)} defaultChecked={user.toggle} className="form-check-input" /> {/*    */}
                                    </div>
                                </td>
                                <td>{user.email}</td>
                                <td>{user.site}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <h3>Deleted Users</h3>
                <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                    <thead className="table-light">
                        <tr>
                            <td>Name</td>
                            <td>Company Name</td>
                            <td>Site</td>
                            <td>Email</td>
                            <td>Active</td>
                            {/* <td>Action</td> */}
                            <td>Restore?</td>
                        </tr>
                    </thead>
                    <tbody>
                        {allUsers.map((user) =>
                            <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.company}</td>
                                <td>{user.site}</td>
                                <td>{user.email}</td>
                                <td>{<input style={{ fontSize: 24 }} type="checkbox" className="form-check-input" defaultChecked={user.active} disabled />}</td>
                                {/* <td>{editUserId && editUserId == user.id ? <div><button className="btn btn-primary" onClick={(e) => saveAllButtonClickHandler(e, user)}>Save</button><button className="btn btn-secondary" onClick={() => setEditUserId('')}>Cancel</button></div> : <button className="btn btn-primary" onClick={(e) => editButtonClickHandler(e, user)}>Edit</button>}</td> */}
                                <td><button className="btn btn-danger" onClick={(e) => RestoreListUser(e, user)}>Restore</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {showSuccess ? (
                    <Alert variant="success">
                        Settings saved successfully.
                    </Alert>
                ) : (
                    null
                )}

                <button style={{ margin: 5 }} className="btn btn-secondary" onClick={handleSuperAdminBack}>Back</button>
                <button style={{ margin: 5 }} className="btn btn-primary" onClick={UpdateSuperAdminDatabaseBoards}>Save</button>
            </div>
        </section>
    )
}

export default SuperAdminArea;