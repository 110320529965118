import React from 'react'

const Login = (props) => {

    const { email, pass, setEmail, setPass, handleLogin, emailError, passError, handleForgottenPasword } = props

    return (
        <section>
            <div>
                <p>{emailError}</p>
                <p>{passError}</p>
                <form>
                    <input style={{ fontSize: 18 }} placeholder="Email" value={email} type="text" required name="email" onChange={(e) => setEmail(e.target.value)} /> <br />
                    <input style={{ fontSize: 18 }} placeholder="Password" type="password" required name="password" sensitive="true" onChange={(e) => setPass(e.target.value)} /> <br />
                    <br />
                    <button style={{ margin: 5 }} className="btn btn-primary" onClick={handleLogin}>Login</button>
                    <button style={{ margin: 5 }} className="btn btn-secondary" onClick={handleForgottenPasword}>Forgotten Password</button>
                </form>
            </div>
            <div style={{ fontSize: 14 }} className='rowLogin'>
                <div className='rowLogin-item'>
                    <p>
                        <b>IME Contracts</b><br />
                        Unit 2<br />
                        Health Park Industrial Estate
                    </p>
                </div>
                <div className='rowLogin-item'>
                    <p>
                        Freshwater Road<br />
                        Chadwell Heath<br />
                        RM8 1RX
                    </p>
                </div>
                <div className='rowLogin-item'>
                    <p>
                        <b>Tel:</b> 0208 599 6570<br />
                        <b>Fax:</b> 0208 597 1134<br />
                        <b>Website:</b> www.imecontracts.co.uk
                    </p>
                </div>
                <div className='rowLogin-item'>
                    <b>VAT Registration No.:</b> 246 1975 42
                </div>
            </div>
        </section>
    )
}

export default Login;