import React, { useState, useEffect } from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import fire from './Firebase'

import logo from './Assets/clientportalbanner.jpg';
import logoSeperator from './Assets/logoSeperator.png';

import Login from './Screens/Login'
import Splash from './Screens/Splash'
import Views from './Screens/Views'
import Board from './Screens/Board'
import ForgottenPassword from './Screens/ForgottenPassword'
import AdminArea from './Screens/AdminArea'
import SuperAdminArea from './Screens/SuperAdminArea'
import ChangePasswordScreen from './Screens/ChangePassword'

function App() {
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passError, setPassError] = useState('');
  const [type, setType] = useState('');
  const [view, setView] = useState('');
  const [changePasswordError, setChangePasswordError] = useState('');
  // const [uid, setUid] = useState('');
  const [chosenBoards, setChosenBoards] = useState([])
  const [boards, setBoards] = useState([]);
  const [title, setTitle] = useState('');
  const [items, setItems] = useState([]);
  const [columnValues, setColumnValues] = useState([]);
  const [forgottenPassword, setForgottenPassword] = useState(false);
  const [forgottenEmail, setForgottenEmail] = useState('');
  const [changePasswordText, setChangePasswordText] = useState('');
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openSuperAdmin, setOpenSuperAdmin] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [adminBoards, setAdminBoards] = useState([])
  const [account, setAccount] = useState('');
  const [submitted, setSubmitted] = useState(false)

  const [admin, setAdmin] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [liveWorksBoards, setLiveWorksBoards] = useState([])
  const [valveBoards, setValveBoards] = useState([])
  const [pipeWorkBoards, setPipeWorkBoards] = useState([])
  const [fabricationBoards, setFabricationBoards] = useState([])
  const [mechanicalBoards, setMechanicalBoards] = useState([])
  const [grabsBoards, setGrabsBoards] = useState([])
  const [machiningBoards, setMachiningBoards] = useState([])
  const [valveSupplyBoards, setValveSupplyBoards] = useState([])
  const [sourcingBoards, setSourcingBoards] = useState([])
  const [selectedBoard, setSelectedBoard] = useState([])
  const [loggedEmail, setLoggedEmail] = useState('')
  const [loggedPass, setLoggedPass] = useState('')
  const [outputString, setOutputString] = useState('');
  const [clientLogo, setClientLogo] = useState('')
  const [userName, setUserName] = useState('')

  const [passwordChange, setPasswordChange] = useState(false)

  const secretKey = 'b0aef1db2c9fd8f76654d91fc74840d5';

  // Create a reference with an initial file path and name
  var storage = fire.storage();
  var storageRef = storage.ref();

  const Encrypt = (inputString) => {
    let CryptoJS = require("crypto-js");
    let encryptedText = CryptoJS.AES.encrypt(inputString, secretKey);
    setOutputString(encryptedText.toString());
    //do something with the new string
    return encryptedText.toString()
  }

  const clearInputs = () => {
    setEmail('');
    setPass('');
  }

  const clearErrors = () => {
    setEmailError('');
    setPassError('');
  }

  const handleLogin = (e) => {
    clearErrors();
    e.preventDefault();

    fire.auth().signInWithEmailAndPassword(email, pass)
      .then(response => {
        fire.database()
          .ref(`/Accounts/${response.user.uid}`)
          .once('value')
          .then(snapshot => {
            var data = snapshot.val()
            var company = ''

            let encryptedPass = Encrypt(pass)

            const accountRef = fire.database().ref(`Accounts/${response.user.uid}/`)

            accountRef.update({ hash: encryptedPass });

            for (var key of Object.keys(data)) {
              company = data.company
            }
            if (company != '') {
              fire.database()
                .ref(`/Clients/${company}`)
                .once('value')
                .then(snapshot => {
                  var data = snapshot.val()
                  var activeUser = undefined

                  for (var key of Object.keys(data)) {
                    activeUser = data[key].active
                  }
                  if (activeUser) {
                    setUser(response.user)
                  }
                });
            }
          });
      })
      .catch(error => {
        switch (error.code) {
          case "auth/invalid-email":
            setEmailError(error.message);
            break;
          case "auth/user-disabled":
            setEmailError("Your user has been deactivated, please contact IME by calling (0208 599 6570) or emailing (sales@imecontracts.co.uk).")
            break;
          case "auth/user-not-found":
            setEmailError(error.message);
            break;
          case "auth/wrong-password":
            setPassError(error.message);
            break;
          default:
            setEmailError('');
            setPassError('');
        }
      })

  }

  const handleAdminArea = () => {
    setOpenAdmin(true)
  }

  const handleSuperAdminArea = () => {
    setOpenSuperAdmin(true)
  }

  const handlePasswordReset = (e) => {
    e.preventDefault();

    if (forgottenEmail !== '') {
      setSubmitted(true)
      fire.auth().sendPasswordResetEmail(forgottenEmail)
        .then(() => {
          setForgottenEmail('')
          setForgottenPassword(false);
          setSubmitted(false)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const handleForgottenPasword = () => {
    setForgottenPassword(true);
  }

  const handleLogout = () => {
    fire.auth().signOut();
    setClientLogo('')
    setUserName('')
  }

  const authListener = () => {
    fire.auth().onAuthStateChanged(user => {
      if (user) {
        clearInputs();
        setUser(user);
      }
      else {
        setUser('');
      }
    })
  }

  const handleAdminBack = () => {
    setOpenAdmin(false)
  }

  const handleSuperAdminBack = () => {
    setOpenSuperAdmin(false)
  }

  const handleViewsBack = () => {
    setType('');
  }

  const handleBoardBack = () => {
    setView('');
    setTitle('')
    if (type === "LiveWorks") {
      setType('')
    }
  }

  const handlePasswordChange = () => {
    setPasswordChange(true)
  }

  const ChangePassword = (e) => {
    e.preventDefault()
    setChangePasswordError('')

    var user = fire.auth().currentUser;

    if (changePasswordText.length >= 6) {
      setSubmitted(true)

      user.updatePassword(changePasswordText).then(function () {
        setSubmitted(false)
        setPasswordChange(false)
        setChangePasswordText('')
      }).catch(function (error) {
        console.log("error = ", error)
      });
    }
    else {
      setChangePasswordError("Your password must be atleast 6 characters long")
    }
  }

  const handlePasswordChangeBack = () => {
    setPasswordChange(false)
    setSubmitted(false)
  }

  const handleForgottenPasswordBack = () => {
    setForgottenPassword(false)
  }

  const GetClientLogo = () => {
    let acc = ''
    fire.database()
      .ref(`/Accounts/${user.uid}`)
      .once('value')
      .then(accSnap => {
        acc = accSnap.val().company
        setAccount(acc)

        fire.database()
          .ref(`/Clients/${acc}/${user.uid}`)
          .once('value')
          .then(clientSnap => {
            setUserName(clientSnap.val().name)
          })

        fire.database()
          .ref(`/Clients/${accSnap.val().company}`)
          .once('value')
          .then(logoSnap => {
            let logoData = logoSnap.val()
            if (logoData) {
              setClientLogo(logoData.logo)
            }
          })
      })


  }

  useEffect(() => {
    authListener()
    // fire.auth().signOut();
    // setClientLogo('')
    // setUserName('')
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <br />
        {clientLogo ?
          <div>
            {/* <img src={logo} alt={"IME Logo"} className="align-middle" height={150} width={300} />
            <img style={{ marginLeft: 30, marginRight: 30 }} src={logoSeperator} alt={"Logo seperator"} className="align-middle" height={150} /> */}
            <img src={clientLogo} alt={"Client Logo"} className="align-middle" height={100} width={600} />
          </div>
          :
          <img src={logo} alt={"IME Logo"} className="align-middle" height={100} width={600} />
        }

        <b>Welcome {userName}</b>

        {/* <h1>IME Contracts</h1> */}
        {view ? (
          <Board
            view={view}
            handleBoardBack={handleBoardBack}
            type={type}
            title={title}
            setTitle={setTitle}
            items={items}
            setItems={setItems}
            columnValues={columnValues}
            setColumnValues={setColumnValues}
            fire={fire}
            selectedBoard={selectedBoard}
            setSelectedBoard={setSelectedBoard}
            account={account}
          />
        ) : user && type ? (
          <Views
            type={type}
            setView={setView}
            handleViewsBack={handleViewsBack}
            liveWorksBoards={liveWorksBoards}
            valveBoards={valveBoards}
            pipeWorkBoards={pipeWorkBoards}
            fabricationBoards={fabricationBoards}
            mechanicalBoards={mechanicalBoards}
            grabsBoards={grabsBoards}
            machiningBoards={machiningBoards}
            valveSupplyBoards={valveSupplyBoards}
            sourcingBoards={sourcingBoards}
            boards={boards}
            setSelectedBoard={setSelectedBoard}
          />
        ) : openSuperAdmin ? (
          <SuperAdminArea
            fire={fire}
            accounts={accounts}
            setAccounts={setAccounts}
            superAdmin={superAdmin}
            handleSuperAdminBack={handleSuperAdminBack}
          />
        ) : openAdmin ? (
          <AdminArea
            fire={fire}
            accounts={accounts}
            setAccounts={setAccounts}
            adminBoards={adminBoards}
            setAdminBoards={setAdminBoards}
            handleAdminBack={handleAdminBack}
            superAdmin={superAdmin}
            handleSuperAdminArea={handleSuperAdminArea}
            user={user}
            GetClientLogo={GetClientLogo}
          />
        ) : passwordChange ? (
          <ChangePasswordScreen
            ChangePassword={ChangePassword}
            handlePasswordChangeBack={handlePasswordChangeBack}
            submitted={submitted}
            setChangePasswordText={setChangePasswordText}
            changePasswordError={changePasswordError}
          />
        ) : user ? (
          <Splash
            handleLogout={handleLogout}
            setType={setType}
            type={type}
            fire={fire}
            chosenBoards={chosenBoards}
            setChosenBoards={setChosenBoards}
            boards={boards}
            setBoards={setBoards}
            handleAdminArea={handleAdminArea}
            user={user}
            account={account}
            setAccount={setAccount}
            admin={admin}
            setAdmin={setAdmin}
            liveWorksBoards={liveWorksBoards}
            setLiveWorksBoards={setLiveWorksBoards}
            valveBoards={valveBoards}
            setValveBoards={setValveBoards}
            pipeWorkBoards={pipeWorkBoards}
            setPipeWorkBoards={setPipeWorkBoards}
            fabricationBoards={fabricationBoards}
            setFabricationBoards={setFabricationBoards}
            mechanicalBoards={mechanicalBoards}
            setMechanicalBoards={setMechanicalBoards}
            grabsBoards={grabsBoards}
            setGrabsBoards={setGrabsBoards}
            machiningBoards={machiningBoards}
            setMachiningBoards={setMachiningBoards}
            valveSupplyBoards={valveSupplyBoards}
            setValveSupplyBoards={setValveSupplyBoards}
            sourcingBoards={sourcingBoards}
            setSourcingBoards={setSourcingBoards}
            setSuperAdmin={setSuperAdmin}
            GetClientLogo={GetClientLogo}
            setView={setView}
            handlePasswordChange={handlePasswordChange}
          />
        ) : forgottenPassword ? (
          <ForgottenPassword
            forgottenEmail={forgottenEmail}
            setForgottenEmail={setForgottenEmail}
            handlePasswordReset={handlePasswordReset}
            handleForgottenPasswordBack={handleForgottenPasswordBack}
            submitted={submitted}
          />
        ) : (
          <Login
            email={email}
            setEmail={setEmail}
            pass={pass}
            setPass={setPass}
            handleLogin={handleLogin}
            emailError={emailError}
            passError={passError}
            handleForgottenPasword={handleForgottenPasword}
          />
        )}
      </header>
    </div>
  );
}

export default App;
