import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyCAyTcv3BzC9DeS381YgbQSk2LeoOKZox0",
    authDomain: "ime-monday-portal.firebaseapp.com",
    projectId: "ime-monday-portal",
    storageBucket: "ime-monday-portal.appspot.com",
    messagingSenderId: "677898969413",
    appId: "1:677898969413:web:2073391ece733b8b6c9e2c",
    measurementId: "G-PCLQSSZ89P"
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;