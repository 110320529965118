import React, { useEffect, useState, useRef } from 'react'
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        maxHeight: '80vh',
        overflowY: 'auto',
		zIndex: '9999'
    }
};

var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const AdminArea = ({ fire, accounts, setAccounts, adminBoards, setAdminBoards, handleAdminBack, superAdmin, handleSuperAdminArea, user, GetClientLogo }) => {
    const [company, setCompany] = useState('')
    const [clients, setClients] = useState([])
    const [users, setUsers] = useState([])
    const [runningUser, setRunningUser] = useState([])

    const [board, setBoard] = useState('')
    const [fields, setFields] = useState([])
    const [boolFields, setBoolFields] = useState([])
    const [boolBoards, setBoolBoards] = useState([])
    const [databaseFields, setDatabaseFields] = useState([])

    const [editUserId, setEditUserId] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [site, setSite] = useState('')
    const [active, setActive] = useState(false)
    const [rowId, setRowId] = useState('')
    const [titleToggle, setTitleToggle] = useState(true)

    const [showSuccess, setShowSuccess] = useState(false);
    const [newCompany, setNewCompany] = useState('')
    const [userUID, setUserUID] = useState('')
    const [chosenUserId, setChosenUserId] = useState('')
    // const [admin, setAdmin] = useState(false)
    const [createCompany, setCreateCompany] = useState(false)
    const [createUser, setCreateUser] = useState(false)
    const [createAllUser, setCreateAllUser] = useState(false)
    const [error, setError] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [newName, setNewName] = useState('')
    const [newSite, setNewSite] = useState('')
    const [newAllUserCompany, setNewAllUserCompany] = useState('')
    const [newActive, setNewActive] = useState(true)
    const [updatedCompany, setUpdatedCompany] = useState('')

    const [valveBoards, setValveBoards] = useState([])
    const [pipeWorkBoards, setPipeWorkBoards] = useState([])
    const [fabricationBoards, setFabricationBoards] = useState([])
    const [mechanicalBoards, setMechanicalBoards] = useState([])
    const [grabsBoards, setGrabsBoards] = useState([])
    const [machiningBoards, setMachiningBoards] = useState([])
    const [valveSupplyBoards, setValveSupplyBoards] = useState([])
    const [sourcingBoards, setSourcingBoards] = useState([])
    const [liveWorksBoards, setLiveWorksBoards] = useState([])

    const [loading, setLoading] = useState(false)
    const [fieldsLoading, setFieldsLoading] = useState(false)
    const [boardError, setBoardError] = useState(false)
    const [originalEmail, setOriginalEmail] = useState('')
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [logoUploadToggle, setLogoUploadToggle] = useState(false);
    const [logoUploadSuccess, setLogoUploadSuccess] = useState(false)
    const [allUsersToggle, setAllUsersToggle] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const [updatedCompanyError, setUpdatedCompanyError] = useState('')
    const [sites, setSites] = useState(undefined)
    const [siteLoading, setSiteLoading] = useState(false)
    const [boolSites, setBoolSites] = useState([])
    const [deactivateCreateButon, setDeactivateCreateButton] = useState(false)
    const [logoError, setLogoError] = useState('')
    const [newCompanyError, setNewCompanyError] = useState('')
    const [newUserError, setNewUserError] = useState('')
    const [clientLogo, setClientLogo] = useState('')
    const [boolStages, setBoolStages] = useState([])
    const [stages, setStages] = useState(undefined)
    const [sitesModal, setSitesModal] = useState(false)
    const [stageModal, setStageModal] = useState(false)
    const [stageLoading, setStageLoading] = useState(false)

    const node = useRef();

    const secretKey = 'b0aef1db2c9fd8f76654d91fc74840d5';

    const Decrypt = (inputString) => {
        let CryptoJS = require("crypto-js");
        let bytes = CryptoJS.AES.decrypt(inputString, secretKey);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        //here is your decrypted text
        return plaintext.toString()
    }

    useEffect(() => {
        setValveBoards([])
        setPipeWorkBoards([])
        setFabricationBoards([])
        setMechanicalBoards([])
        setGrabsBoards([])
        setMachiningBoards([])
        setValveSupplyBoards([])
        setSourcingBoards([])
        setBoolBoards([])

        if (!clients == []) {
            fire.database()
                .ref(`/Clients`)
                .once('value')
                .then(snapshot => {
                    var data = snapshot.val()
                    var clientLoopData = []

                    for (var key of Object.keys(data)) {
                        if (data[key].deleted !== true) {
                            clientLoopData.push({ id: key, company: key })
                        }
                    }
                    setClients(clientLoopData)
                });
        }

        var usersLoopData = []

        fire.database()
            .ref(`/Clients/`)
            .once('value')
            .then(snapshot => {
                var allData = snapshot.val()

                for (var allKey of Object.keys(allData)) {
                    for (var clientKey of Object.keys(allData[allKey])) {
                        if (clientKey !== "logo") {
                            if (allData[allKey][clientKey].deleted === false) {
                                usersLoopData.push({ id: clientKey, active: allData[allKey][clientKey].active, name: allData[allKey][clientKey].name, email: allData[allKey][clientKey].email, site: allData[allKey][clientKey].site, company: allKey })
                            }
                        }
                    }
                }
                setAllUsers(usersLoopData)
            });
    }, []);

    // const UpdateDatabaseFields = (e) => {
    //     e.preventDefault();
    //     setShowSuccess(true)
    //     setTimeout(function () {
    //         setShowSuccess(false)
    //     }, 3000);
    // };

    const UpdateDatabaseBoards = (e) => {
        e.preventDefault();

        if (boolBoards !== null && boolBoards !== undefined && boolBoards.length === undefined) {
            setShowSuccess(true)

            const fieldsRef = fire.database().ref(`Boards/${company}/${chosenUserId}`)

            const boardsArray = []

            boardsArray.push({ "Valves": valveBoards ? valveBoards : [], "Pipe_Work": pipeWorkBoards ? pipeWorkBoards : [], "Fabrication": fabricationBoards ? fabricationBoards : [], "Mechanical": mechanicalBoards ? mechanicalBoards : [], "Grabs": grabsBoards ? grabsBoards : [], "Machining": machiningBoards ? machiningBoards : [], "Valve_Supply": valveSupplyBoards ? valveSupplyBoards : [], "Sourcing": sourcingBoards ? sourcingBoards : [], "Boards": boolBoards ? boolBoards : [], "LiveWorks": liveWorksBoards ? liveWorksBoards : [] })
            fieldsRef.set(boardsArray[0]);

            setTimeout(function () {
                setShowSuccess(false)
            }, 3000);


            if (boolFields !== null && boolFields !== undefined && boolFields.length === undefined) {
                const fieldsRef = fire.database().ref(`Fields/${company}/${board}/${chosenUserId}`)
                fieldsRef.set(boolFields);
            }
            if (boolSites !== null && boolSites !== undefined && boolSites.length === undefined) {
                const sitesRef = fire.database().ref(`Sites/${company}/${board}/${chosenUserId}`)
                sitesRef.set(boolSites);
            }
            if (boolStages !== null && boolStages !== undefined && boolStages.length === undefined) {
                const stagesRef = fire.database().ref(`Stages/${company}/${board}/${chosenUserId}`)
                stagesRef.set(boolStages);
            }
        }
        else {
            setBoardError(true)

            setTimeout(function () {
                setBoardError(false)
            }, 3000);
        }

    };

    const GetSiteCompanies = (colId) => {
        setSiteLoading(true)
        setSitesModal(true)

        let dbSites = []

        fire.database()
            .ref(`/Sites/${company}/${board}/${chosenUserId}/`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()
                if (data !== null && data !== undefined) {
                    dbSites = data
                    setBoolSites(data)
                }
            });

        let siteNameQuery = `{
            boards(ids: ${board}) {
                items {
                    column_values(ids: "${colId}") {
                    text
                    }
                }
            }
        }`;

        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': siteNameQuery
            })
        })
            .then(res => res.json())
            .then(res => {

                let sites = []

                res.data.boards[0].items.map((item) => {
                    item.column_values.map((column) => {
                        if (column.text !== "" && !sites.includes(column.text)) {
                            sites.push(column.text)
                        }
                    })
                })

                let toggledSites = []

                sites.map(site => {
                    if (dbSites != null || dbSites != undefined && dbSites.includes(site)) {
                        if (dbSites[site] == true) {
                            toggledSites.push({ id: site, toggle: true })
                        }
                        else if (dbSites[site] == false) {
                            toggledSites.push({ id: site, toggle: false })
                        }
                        else {
                            toggledSites.push({ id: site, toggle: false })
                        }
                    }
                    else {
                        toggledSites.push({ id: site, toggle: false })
                    }
                })

                setSites(toggledSites)
                setSiteLoading(false)
            })

    }

    const GetStages = (colId) => {
        setStageLoading(true)
        setStageModal(true)

        let dbStages = []

        fire.database()
            .ref(`/Stages/${company}/${board}/${chosenUserId}/`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()
                if (data !== null && data !== undefined) {
                    dbStages = data
                    setBoolStages(data)
                }
            });

        let stageQuery = `{
            boards(ids: ${board}) {
                items {
                    column_values(ids: "${colId}") {
                    text
                    }
                }
            }
        }`;

        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': stageQuery
            })
        })
            .then(res => res.json())
            .then(res => {

                let stages = []

                res.data.boards[0].items.map((item) => {
                    item.column_values.map((column) => {
                        if (column.text !== "" && !stages.includes(column.text)) {
                            stages.push(column.text)
                        }
                    })
                })

                let toggledStages = []

                stages.map(stage => {
                    if (dbStages != null || dbStages != undefined && dbStages.includes(stage)) {
                        if (dbStages[stage] == true) {
                            toggledStages.push({ id: stage, toggle: true })
                        }
                        else if (dbStages[stage] == false) {
                            toggledStages.push({ id: stage, toggle: false })
                        }
                        else {
                            toggledStages.push({ id: stage, toggle: false })
                        }
                    }
                    else {
                        toggledStages.push({ id: stage, toggle: false })
                    }
                })

                setStages(toggledStages)
                setStageLoading(false)
            })

    }

    const GetFields = (id) => {
        setBoard(id)
        setRowId(id)
        setFieldsLoading(true)
        setTitleToggle(false)
        setBoolSites([])

        let newQuery = `{ 
            boards(ids: ${id}) {
                columns {
                    id
                    title
                }
            }
        }`;

        let dbFields = []

        fire.database()
            .ref(`/Fields/${company}/${id}/${chosenUserId}/`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()
                dbFields = data
                setBoolFields(data)
            });

        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': newQuery
            })
        })
            .then(res => res.json())
            .then(res => {
                var fieldList = []
                res.data.boards[0].columns.map(val => {
                    if (!fieldList.includes(val.title) && val.title !== "Name") {
                        fieldList.push({ id: val.id, title: val.title })
                    }
                })

                let toggledFields = []
                let toggledTitle = false

                if (dbFields != null || dbFields != undefined) {
                    for (var key of Object.keys(dbFields)) {
                        if (key === "Title") {
                            console.log("dbFields.Title = ", dbFields.Title)
                            setTitleToggle(dbFields.Title)
                            toggledTitle = dbFields.Title
                            toggledFields.push({ id: "Title", title: "Title", toggle: dbFields.Title })
                        }
                    }
                }

                // if (toggledTitle === false) {
                //     setTitleToggle(false)
                // }

                fieldList.map(field => {
                    if (field.title !== "Comments") {
                        if (dbFields != null || dbFields != undefined && dbFields.includes(field.id)) {
                            if (dbFields[field.id] == true) {
                                toggledFields.push({ id: field.id, title: field.title, toggle: true })
                            }
                            else if (dbFields[field.id] == false) {
                                toggledFields.push({ id: field.id, title: field.title, toggle: false })
                            }
                            else {
                                toggledFields.push({ id: field.id, title: field.title, toggle: false })
                            }
                        }
                        else {
                            toggledFields.push({ id: field.id, title: field.title, toggle: false })
                        }
                    }
                })

                setFields(toggledFields)
                setFieldsLoading(false)
            });
    }

    const CreateNewCompany = (e) => {
        e.preventDefault();

        if (newCompany == '') {
            setNewCompanyError("Please enter the Company Name")
        }
        else if (format.test(newCompany)) {
            setNewCompanyError("You cannot have special characters in a company name. Please change the new company name and submit again.")
        }
        else if (!/\S/.test(newCompany)) {
            setNewCompanyError("You cannot have a blank value for a company name. Please change the new company name and submit again.")
        }
        else {
            let userId = ''
            let userEmail = ''

            // Get the first empty user
            fire.database()
                .ref(`/Company`)
                .once('value')
                .then(snapshot => {
                    var data = snapshot.val()
                    for (var key of Object.keys(data)) {
                        if (userId === '') {
                            userId = key
                            userEmail = data[key].email
                        }
                    }

                    const accountRef = fire.database().ref(`Accounts/${userId}/`)
                    const clientRef = fire.database().ref(`Clients/${newCompany}/${userId}/`)
                    const deletedRef = fire.database().ref(`Clients/${newCompany}/`)

                    deletedRef.update({ deleted: false })
                    accountRef.update({ admin: false, company: newCompany, superAdmin: false });
                    clientRef.update({ active: true, email: userEmail, name: "New User", site: "New Site", deleted: false });

                    fire.database().ref(`Company/${userId}/`).remove()

                    setCreateCompany(!createCompany)
                    setNewCompany('')
                    setError('')
                    setCompany('')

                    if (!clients == []) {
                        fire.database()
                            .ref(`/Clients`)
                            .once('value')
                            .then(snapshot => {
                                var data = snapshot.val()
                                var clientLoopData = []

                                for (var key of Object.keys(data)) {
                                    if (data[key].deleted !== true) {
                                        clientLoopData.push({ id: key, company: key })
                                    }
                                }
                                setClients(clientLoopData)
                            });
                    }
                });
        }
    }

    const CreateUser = (e) => {
        e.preventDefault()
        setDeactivateCreateButton(true)

        // if (userUID === '') {
        //     setNewUserError("Please enter the User UID")
        // }
        if (newName === '' || !/\S/.test(newName)) {
            setNewUserError("Please enter the Name")
            setDeactivateCreateButton(false)
        }
        else if (newEmail === '' || !/\S/.test(newEmail)) {
            setNewUserError("Please enter the Email")
            setDeactivateCreateButton(false)
        }
        else if (newSite === '' || !/\S/.test(newSite)) {
            setNewUserError("Please enter the Site")
            setDeactivateCreateButton(false)
        }
        else {
            let userId = ''
            let userEmail = ''

            // Get the first empty user
            fire.database()
                .ref(`/Company`)
                .once('value')
                .then(snapshot => {
                    var data = snapshot.val()
                    for (var key of Object.keys(data)) {
                        if (userId === '') {
                            userId = key
                            userEmail = data[key].email
                        }
                    }

                    fire.database()
                        .ref(`/Accounts/${user.uid}/`)
                        .once('value')
                        .then(snapshot => {
                            var accountData = snapshot.val()

                            let hash = ''

                            for (var accKey of Object.keys(accountData)) {
                                if (hash === '') {
                                    hash = accountData.hash
                                }
                            }
                            const oldPass = Decrypt(hash)

                            // log in the first empty user and change their email
                            fire.auth().signInWithEmailAndPassword(userEmail, "password")
                                .then(response => {
                                    var newUser = fire.auth().currentUser;
                                    newUser.updateEmail(newEmail).then(function () {

                                        //Sign current user back in
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {
                                                fire.database().ref(`Company/${userId}/`).remove()

                                                const accountRef = fire.database().ref(`Accounts/${userId}/`)
                                                const clientRef = fire.database().ref(`Clients/${company}/${userId}/`)

                                                accountRef.set({ admin: false, company: company, superAdmin: false });
                                                clientRef.set({ active: true, email: newEmail, name: newName, site: newSite, deleted: false })

                                                setCreateUser(!createUser)
                                                // setUserUID('')
                                                setNewName('')
                                                setNewEmail('')
                                                setNewSite('')
                                                setNewUserError('')

                                                fire.database()
                                                    .ref(`/Clients/${company}`)
                                                    .once('value')
                                                    .then(snapshot => {
                                                        var data = snapshot.val()
                                                        var usersLoopData = []

                                                        for (var key of Object.keys(data)) {
                                                            if (key !== "logo") {
                                                                if (data[key].deleted === false) {
                                                                    usersLoopData.push({ id: key, active: data[key].active, name: data[key].name, email: data[key].email, site: data[key].site })
                                                                }
                                                            }
                                                        }
                                                        setUsers(usersLoopData)
                                                        setDeactivateCreateButton(false)
                                                    });
                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    }).catch(function (error) {
                                        setNewUserError("The email could not be set as it is already in use")
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {
                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    });
                                })
                                .catch(error => {
                                    console.log("error = ", error)
                                })
                        });
                });
        }
    }

    const CreateNewAllUser = (e) => {
        e.preventDefault()
        setDeactivateCreateButton(true)

        // if (userUID === '') {
        //     setError("Please enter the User UID")
        // }
        if (newName === '' || !/\S/.test(newName)) {
            setError("Please enter the Name")
            setDeactivateCreateButton(false)
        }
        else if (newAllUserCompany === '') {
            setError("Please select a company")
            setDeactivateCreateButton(false)
        }
        else if (newSite === '' || !/\S/.test(newSite)) {
            setError("Please enter the Site")
            setDeactivateCreateButton(false)
        }
        else if (newEmail === '' || !/\S/.test(newEmail)) {
            setError("Please enter the Email")
            setDeactivateCreateButton(false)
        }
        else {
            let userId = ''
            let userEmail = ''

            // Get the first empty user
            fire.database()
                .ref(`/Company`)
                .once('value')
                .then(snapshot => {
                    var data = snapshot.val()
                    for (var key of Object.keys(data)) {
                        if (userId === '') {
                            userId = key
                            userEmail = data[key].email
                        }
                    }

                    fire.database()
                        .ref(`/Accounts/${user.uid}/`)
                        .once('value')
                        .then(snapshot => {
                            var accountData = snapshot.val()

                            let hash = ''

                            for (var accKey of Object.keys(accountData)) {
                                if (hash === '') {
                                    hash = accountData.hash
                                }
                            }
                            const oldPass = Decrypt(hash)

                            // log in the first empty user and change their email
                            fire.auth().signInWithEmailAndPassword(userEmail, "password")
                                .then(response => {
                                    var newUser = fire.auth().currentUser;
                                    newUser.updateEmail(newEmail).then(function () {

                                        //Sign current user back in
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {
                                                fire.database().ref(`Company/${userId}/`).remove()

                                                const accountRef = fire.database().ref(`Accounts/${userId}/`)
                                                const clientRef = fire.database().ref(`Clients/${newAllUserCompany}/${userId}/`)

                                                accountRef.set({ admin: false, company: newAllUserCompany, superAdmin: false });
                                                clientRef.set({ active: newActive, email: newEmail, name: newName, site: newSite, deleted: false })

                                                setCreateAllUser(!createAllUser)
                                                // setUserUID('')
                                                setNewName('')
                                                setNewEmail('')
                                                setNewSite('')
                                                setError('')
                                                setNewActive(true)
                                                setNewAllUserCompany('')

                                                var usersLoopData = []

                                                fire.database()
                                                    .ref(`/Clients/`)
                                                    .once('value')
                                                    .then(snapshot => {
                                                        var allData = snapshot.val()

                                                        for (var allKey of Object.keys(allData)) {
                                                            for (var clientKey of Object.keys(allData[allKey])) {
                                                                if (clientKey !== "logo") {
                                                                    if (allData[allKey][clientKey].deleted === false) {
                                                                        usersLoopData.push({ id: clientKey, active: allData[allKey][clientKey].active, name: allData[allKey][clientKey].name, email: allData[allKey][clientKey].email, site: allData[allKey][clientKey].site, company: allKey })
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        setAllUsers(usersLoopData)
                                                        setDeactivateCreateButton(false)
                                                    });
                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    }).catch(function (error) {
                                        setError("The email could not be set as it is already in use")
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {
                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    });
                                })
                                .catch(error => {
                                    console.log("error = ", error)
                                })
                        });
                });
        }
    }

    useEffect(() => {
        fire.auth().onAuthStateChanged((user) => {
            setRunningUser(user)
        })
    }, []);

    const SetUsers = (e) => {
        setBoard('')
        setRowId('')
        setBoolFields([])
        setFields([])
        setChosenUserId('')
        setError('')
        setUpdatedCompanyError('')
        setNewUserError('')

        setCompany(e.target.value)
        setValveBoards([])
        setPipeWorkBoards([])
        setFabricationBoards([])
        setMechanicalBoards([])
        setGrabsBoards([])
        setMachiningBoards([])
        setValveSupplyBoards([])
        setSourcingBoards([])
        setBoolBoards([])

        setClientLogo('')
        setLogoUploadToggle(false)

        if (e.target.value != "Select Company..." && (e.target.value != null || e.target.value != undefined || e.target.value != '')) {
            setLoading(true)

            var usersLoopData = []

            fire.database()
                .ref(`/Clients/${e.target.value}`)
                .once('value')
                .then(snapshot => {
                    var data = snapshot.val()

                    for (var key of Object.keys(data)) {
                        if (key !== "logo") {
                            if (data[key].deleted === false) {
                                usersLoopData.push({ id: key, active: data[key].active, name: data[key].name, email: data[key].email, site: data[key].site })
                            }
                        }
                        else {
                            setClientLogo(data.logo)
                        }
                    }
                    setUsers(usersLoopData)
                    setLoading(false)
                });
        }
    }

    const UpdateCompanyName = (e) => {
        e.preventDefault()

        // if (!updatedCompany.includes("/")) {
        if (updatedCompany === '' || format.test(updatedCompany)) {
            setUpdatedCompanyError("You cannot have special characters in a company name. Please change the new company name and submit again.")
        }
        else if (!/\S/.test(updatedCompany)) {
            setUpdatedCompanyError("You cannot have a blank value for a company name. Please change the new company name and submit again.")
        }
        else {
            setUpdatedCompanyError('')

            let clientsData = []
            let boardsData = []
            let fieldsData = []
            let sitesData = []

            const fieldsRef = fire.database().ref(`Fields/${updatedCompany}/`)
            const boardsRef = fire.database().ref(`Boards/${updatedCompany}/`)
            const clientsRef = fire.database().ref(`Clients/${updatedCompany}/`)
            const sitesRef = fire.database().ref(`Sites/${updatedCompany}/`)

            fire.database()
                .ref(`/Accounts/`)
                .once('value')
                .then(snapshot => {
                    var data = snapshot.val()

                    if (data !== null && data !== undefined) {
                        for (var key of Object.keys(data)) {
                            if (data[key].company === company) {
                                const accountsRef = fire.database().ref(`Accounts/${key}/`)
                                accountsRef.update({ company: updatedCompany });
                            }
                        }
                    }
                });

            fire.database()
                .ref(`/Clients/${company}`)
                .once('value')
                .then(snapshot => {
                    var data = snapshot.val()

                    if (data !== null && data !== undefined) {
                        clientsData = data
                        clientsRef.set(clientsData)
                        fire.database().ref(`Clients/${company}/`).remove()
                    }
                });

            fire.database()
                .ref(`/Boards/${company}/`)
                .once('value')
                .then(snapshot => {
                    var brdData = snapshot.val()

                    if (brdData !== null && brdData !== undefined) {
                        boardsData = brdData
                        boardsRef.set(boardsData);
                        fire.database().ref(`Boards/${company}/`).remove()
                    }
                });

            fire.database()
                .ref(`/Fields/${company}/`)
                .once('value')
                .then(snapshot => {
                    var fieldData = snapshot.val()

                    if (fieldData !== null && fieldData !== undefined) {
                        fieldsData = fieldData
                        fieldsRef.set(fieldsData);
                        fire.database().ref(`Fields/${company}/`).remove()
                    }
                });

            fire.database()
                .ref(`/Sites/${company}/`)
                .once('value')
                .then(snapshot => {
                    var siteData = snapshot.val()

                    if (siteData !== null && siteData !== undefined) {
                        sitesData = siteData
                        sitesRef.set(sitesData);
                        fire.database().ref(`Sites/${company}/`).remove()
                    }
                });

            if (!clients == []) {
                fire.database()
                    .ref(`/Clients`)
                    .once('value')
                    .then(snapshot => {
                        var clientData = snapshot.val()
                        var clientLoopData = []

                        for (var key of Object.keys(clientData)) {
                            if (clientData[key].deleted !== true) {
                                clientLoopData.push({ id: key, company: key })
                            }
                        }
                        setClients(clientLoopData)
                        setCompany('')
                    });
            }
        }
        // }
        // else {
        //     setUpdatedCompanyError("You cannot have '/' in a company name. Please change the new company name and submit again.")
        // }
    }

    const GetBoards = (userId) => {

        setLoading(true)
        setBoard('')
        setRowId('')
        setFields([])
        setChosenUserId(userId)

        let toggledBoards = []
        let dbBoards = null
        let dbLiveWorksBoards = null
        let dbValveBoards = null
        let dbPipeWorkBoards = null
        let dbFabricationBoards = null
        let dbMechanicalBoards = null
        let dbGrabsBoards = null
        let dbMachiningBoards = null
        let dbValveSupplyBoards = null
        let dbSourcingBoards = null
        let boardIds = []


        fire.database()
            .ref(`/Boards/${company}/${userId}`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                if (data != null || data != undefined) {
                    dbBoards = data.Boards
                    dbLiveWorksBoards = data.LiveWorks
                    dbValveBoards = data.Valves
                    dbPipeWorkBoards = data.Pipe_Work
                    dbFabricationBoards = data.Fabrication
                    dbMechanicalBoards = data.Mechanical
                    dbGrabsBoards = data.Grabs
                    dbMachiningBoards = data.Machining
                    dbValveSupplyBoards = data.Valve_Supply
                    dbSourcingBoards = data.Sourcing

                    setValveBoards(data.Valves)
                    setLiveWorksBoards(data.LiveWorks)
                    setPipeWorkBoards(data.Pipe_Work)
                    setFabricationBoards(data.Fabrication)
                    setMechanicalBoards(data.Mechanical)
                    setGrabsBoards(data.Grabs)
                    setMachiningBoards(data.Machining)
                    setValveSupplyBoards(data.Valve_Supply)
                    setSourcingBoards(data.Sourcing)
                    setBoolBoards(data.Boards)
                }
            });


        fire.database()
            .ref(`/AdminBoards/`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                if (data != null) {
                    for (var key of Object.keys(data)) {
                        boardIds.push({ id: key, toggle: data[key] })
                    }
                }
            });

        let query = `{ 
            boards(limit: 200)
            {
                name 
                id
            } 
        }`;

        fetch("https://api.monday.com/v2", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjk5ODEwOTY1LCJ1aWQiOjE1MzUwNTMzLCJpYWQiOiIyMDIxLTAyLTE2VDEyOjE3OjQ1LjAwMFoiLCJwZXIiOiJtZTp3cml0ZSIsImFjdGlkIjo0MjU4MjgwLCJyZ24iOiJ1c2UxIn0.wrR7q2mQTPRJ8tOzSN2IVLDG81DJ_d-VxRkpSPSLZ2M'
            },
            body: JSON.stringify({
                'query': query
            })
        })
            .then(res => res.json())
            .then(res => {
                let sortedBoards = res.data.boards.sort(compare);

                if (boardIds !== null) {
                    for (var brdKey of Object.keys(boardIds)) {
                        if (boardIds[brdKey].toggle === true) {
                            for (var key of Object.keys(sortedBoards)) {
                                if (dbBoards !== null) {
                                    if (boardIds[brdKey].id.includes(sortedBoards[key].id)) {
                                        let liveWorks = false
                                        let valves = false
                                        let pipe_work = false
                                        let fabrication = false
                                        let mechanical = false
                                        let grabs = false
                                        let machining = false
                                        let valve_supply = false
                                        let sourcing = false

                                        if (dbLiveWorksBoards !== undefined) {
                                            if (dbLiveWorksBoards[sortedBoards[key].id] === true) {
                                                liveWorks = true
                                            }
                                        }

                                        if (dbValveBoards !== undefined) {
                                            if (dbValveBoards[sortedBoards[key].id] === true) {
                                                valves = true
                                            }
                                        }

                                        if (dbPipeWorkBoards !== undefined) {
                                            if (dbPipeWorkBoards[sortedBoards[key].id] === true) {
                                                pipe_work = true
                                            }
                                        }

                                        if (dbFabricationBoards !== undefined) {
                                            if (dbFabricationBoards[sortedBoards[key].id] === true) {
                                                fabrication = true
                                            }
                                        }

                                        if (dbMechanicalBoards !== undefined) {
                                            if (dbMechanicalBoards[sortedBoards[key].id] === true) {
                                                mechanical = true
                                            }
                                        }

                                        if (dbGrabsBoards !== undefined) {
                                            if (dbGrabsBoards[sortedBoards[key].id] === true) {
                                                grabs = true
                                            }
                                        }

                                        if (dbMachiningBoards !== undefined) {
                                            if (dbMachiningBoards[sortedBoards[key].id] === true) {
                                                machining = true
                                            }
                                        }

                                        if (dbValveSupplyBoards !== undefined) {
                                            if (dbValveSupplyBoards[sortedBoards[key].id] === true) {
                                                valve_supply = true
                                            }
                                        }

                                        if (dbSourcingBoards !== undefined) {
                                            if (dbSourcingBoards[sortedBoards[key].id] === true) {
                                                sourcing = true
                                            }
                                        }


                                        if (!toggledBoards.includes(sortedBoards[key].id)) {
                                            toggledBoards.push({ id: sortedBoards[key].id, name: sortedBoards[key].name, toggle: dbBoards[sortedBoards[key].id] !== undefined ? dbBoards[sortedBoards[key].id] : false, liveWorks: liveWorks, valves: valves, pipe_work: pipe_work, fabrication: fabrication, mechanical: mechanical, grabs: grabs, machining: machining, valve_supply: valve_supply, sourcing: sourcing })
                                        }
                                        else {
                                            toggledBoards.push({ id: sortedBoards[key].id, name: sortedBoards[key].name, toggle: false, liveWorks: liveWorks, valves: valves, pipe_work: pipe_work, fabrication: fabrication, mechanical: mechanical, grabs: grabs, machining: machining, valve_supply: valve_supply, sourcing: sourcing })
                                        }
                                    }
                                }
                                else {

                                    if (boardIds[brdKey].id.includes(sortedBoards[key].id)) {
                                        toggledBoards.push({ id: sortedBoards[key].id, name: sortedBoards[key].name, toggle: false, liveWorks: false, valves: false, pipe_work: false, fabrication: false, mechanical: false, grabs: false, machining: false, valve_supply: false, sourcing: false })
                                    }
                                }
                            }
                        }
                    }
                }
                setAdminBoards(toggledBoards)
                setLoading(false)
            })

    }

    function compare(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    const UpdateSites = (e) => {
        var newEntry = Object.assign({}, boolSites, ({ [e.target.value]: e.target.checked }));
        setBoolSites(newEntry);
    }

    const UpdateStages = (e) => {
        var newEntry = Object.assign({}, boolStages, ({ [e.target.value]: e.target.checked }));
        setBoolStages(newEntry);
    }

    const UpdateFields = (e) => {
        var newEntry = Object.assign({}, boolFields, ({ [e.target.value]: e.target.checked }));
        setBoolFields(newEntry);
    }

    const UpdateBoards = (e, brdName) => {
        var newEntry = Object.assign({}, boolBoards, ({ [e.target.value]: e.target.checked }));
        setBoolBoards(newEntry);

        // if (brdName.includes("Live Works") || brdName.includes("LiveWorks") || brdName.includes("LW")) {
        //     var newLWEntry = Object.assign({}, liveWorksBoards, ({ [e.target.value]: e.target.checked }));
        //     setLiveWorksBoards(newLWEntry);
        // }
    }

    const editButtonClickHandler = (e, user) => {
        e.preventDefault();
        setEditUserId(user.id)
        setName(user.name)
        setEmail(user.email)
        setOriginalEmail(user.email)
        setSite(user.site)
        setActive(user.active)
    }

    const DeleteUser = (e, user) => {
        e.preventDefault();

        const clientRef = fire.database().ref(`Clients/${company}/${user.id}/`)

        clientRef.update({ deleted: true, active: false });


        fire.database()
            .ref(`/Clients/${company}`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()
                var usersLoopData = []

                for (var key of Object.keys(data)) {
                    if (key !== "logo") {
                        if (data[key].deleted === false) {
                            usersLoopData.push({ id: key, active: data[key].active, name: data[key].name, email: data[key].email, site: data[key].site })
                        }
                    }
                }
                setUsers(usersLoopData)
                setChosenUserId('')
            });


    }

    const DeleteListUser = (e, user) => {
        e.preventDefault();
        setChosenUserId('')

        const clientRef = fire.database().ref(`Clients/${user.company}/${user.id}/`)

        clientRef.update({ deleted: true, active: false });


        var usersLoopData = []

        fire.database()
            .ref(`/Clients/`)
            .once('value')
            .then(snapshot => {
                var allData = snapshot.val()

                for (var allKey of Object.keys(allData)) {
                    for (var clientKey of Object.keys(allData[allKey])) {
                        if (clientKey !== "logo") {
                            if (allData[allKey][clientKey].deleted === false) {
                                usersLoopData.push({ id: clientKey, active: allData[allKey][clientKey].active, name: allData[allKey][clientKey].name, email: allData[allKey][clientKey].email, site: allData[allKey][clientKey].site, company: allKey })
                            }
                        }
                    }
                }
                setAllUsers(usersLoopData)
            });

    }

    const saveButtonClickHandler = (e) => {
        e.preventDefault();

        fire.database()
            .ref(`/Accounts/${user.uid}/`)
            .once('value')
            .then(snapshot => {
                var runningAccountData = snapshot.val()

                let runningUserHash = ''

                for (var accKey of Object.keys(runningAccountData)) {
                    if (runningUserHash === '') {
                        runningUserHash = runningAccountData.hash
                    }
                }
                const oldPass = Decrypt(runningUserHash)

                fire.database()
                    .ref(`/Accounts/${editUserId}/`)
                    .once('value')
                    .then(snapshot => {
                        var editAccountData = snapshot.val()

                        let hash = ''

                        for (var editAccKey of Object.keys(editAccountData)) {
                            if (hash === '') {
                                hash = editAccountData.hash
                            }
                        }
                        let editPass = ''

                        if (hash !== undefined) {
                            editPass = Decrypt(hash)
                        }
                        else {
                            editPass = "password"
                        }

                        if (originalEmail !== email) {
                            // log in the first empty user and change their email
                            fire.auth().signInWithEmailAndPassword(originalEmail, editPass)
                                .then(response => {
                                    var newUser = fire.auth().currentUser;
                                    newUser.updateEmail(email).then(function () {
                                        //Sign current user back in
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {
                                                const clientRef = fire.database().ref(`Clients/${company}/${editUserId}/`)

                                                clientRef.update({ name: name, email: email, site: site, active: active });
                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    }).catch(function (error) {
                                        setNewUserError("The email could not be updated as it is already in use")
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {
                                                fire.database()
                                                    .ref(`/Clients/${company}`)
                                                    .once('value')
                                                    .then(snapshot => {
                                                        var data = snapshot.val()
                                                        var usersLoopData = []

                                                        for (var key of Object.keys(data)) {
                                                            if (key !== "logo") {
                                                                if (data[key].deleted === false) {
                                                                    usersLoopData.push({ id: key, active: data[key].active, name: data[key].name, email: data[key].email, site: data[key].site })
                                                                }
                                                            }
                                                        }
                                                        setUsers(usersLoopData)
                                                    });
                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    });
                                })
                                .catch(error => {
                                    console.log("error = ", error)
                                })
                        }
                        else {
                            const clientRef = fire.database().ref(`Clients/${company}/${editUserId}/`)

                            clientRef.update({ name: name, email: email, site: site, active: active });

                            fire.database()
                                .ref(`/Clients/${company}`)
                                .once('value')
                                .then(snapshot => {
                                    var data = snapshot.val()
                                    var usersLoopData = []

                                    for (var key of Object.keys(data)) {
                                        if (key !== "logo") {
                                            if (data[key].deleted === false) {
                                                usersLoopData.push({ id: key, active: data[key].active, name: data[key].name, email: data[key].email, site: data[key].site })
                                            }
                                        }
                                    }
                                    setUsers(usersLoopData)
                                });
                        }
                    })

            })

        setName('')
        setEmail('')
        setActive(false)
        setEditUserId('')
        setSite('')
    }

    const saveAllButtonClickHandler = (e, editUser) => {
        e.preventDefault();

        setNewUserError('')
        var usersLoopData = []

        fire.database()
            .ref(`/Accounts/${user.uid}/`)
            .once('value')
            .then(snapshot => {
                var runningAccountData = snapshot.val()

                let runningUserHash = ''

                for (var accKey of Object.keys(runningAccountData)) {
                    if (runningUserHash === '') {
                        runningUserHash = runningAccountData.hash
                    }
                }
                const oldPass = Decrypt(runningUserHash)

                fire.database()
                    .ref(`/Accounts/${editUserId}/`)
                    .once('value')
                    .then(snapshot => {
                        var editAccountData = snapshot.val()

                        let hash = ''

                        for (var editAccKey of Object.keys(editAccountData)) {
                            if (hash === '') {
                                hash = editAccountData.hash
                            }
                        }
                        let editPass = ''

                        if (hash !== undefined) {
                            editPass = Decrypt(hash)
                        }
                        else {
                            editPass = "password"
                        }

                        if (originalEmail !== email) {
                            // log in the first empty user and change their email
                            fire.auth().signInWithEmailAndPassword(originalEmail, editPass)
                                .then(response => {
                                    var newUser = fire.auth().currentUser;
                                    newUser.updateEmail(email).then(function () {
                                        //Sign current user back in
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {
                                                const clientRef = fire.database().ref(`Clients/${editUser.company}/${editUserId}/`)

                                                clientRef.update({ name: name, email: email, site: site, active: active });
                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    }).catch(function (error) {
                                        setNewUserError("The email could not be updated as it is already in use")
                                        fire.auth().signInWithEmailAndPassword(user.email, oldPass)
                                            .then(response => {

                                                fire.database()
                                                    .ref(`/Clients/`)
                                                    .once('value')
                                                    .then(snapshot => {
                                                        var allData = snapshot.val()

                                                        for (var allKey of Object.keys(allData)) {
                                                            for (var clientKey of Object.keys(allData[allKey])) {
                                                                if (clientKey !== "logo") {
                                                                    if (allData[allKey][clientKey].deleted === false) {
                                                                        usersLoopData.push({ id: clientKey, active: allData[allKey][clientKey].active, name: allData[allKey][clientKey].name, email: allData[allKey][clientKey].email, site: allData[allKey][clientKey].site, company: allKey })
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        setAllUsers(usersLoopData)
                                                    });

                                            })
                                            .catch(error => {
                                                console.log("error = ", error)
                                            })
                                    });
                                })
                                .catch(error => {
                                    console.log("error = ", error)
                                })
                        }
                        else {
                            const clientRef = fire.database().ref(`Clients/${editUser.company}/${editUserId}/`)

                            clientRef.update({ name: name, email: email, site: site, active: active });

                            fire.database()
                                .ref(`/Clients/`)
                                .once('value')
                                .then(snapshot => {
                                    var allData = snapshot.val()

                                    for (var allKey of Object.keys(allData)) {
                                        for (var clientKey of Object.keys(allData[allKey])) {
                                            if (clientKey !== "logo") {
                                                if (allData[allKey][clientKey].deleted === false) {
                                                    usersLoopData.push({ id: clientKey, active: allData[allKey][clientKey].active, name: allData[allKey][clientKey].name, email: allData[allKey][clientKey].email, site: allData[allKey][clientKey].site, company: allKey })
                                                }
                                            }
                                        }
                                    }
                                    setAllUsers(usersLoopData)
                                });
                        }
                    })

            })

        setName('')
        setEmail('')
        setActive(false)
        setEditUserId('')
        setSite('')
    }

    const ToggleNewCompany = (e) => {
        e.preventDefault()
        setCreateCompany(!createCompany)
        setNewCompanyError('')
    }

    const ToggleNewAllUser = (e) => {
        e.preventDefault()
        setCreateAllUser(!createAllUser)
    }

    const ToggleAllUsers = (e) => {
        e.preventDefault()
        setAllUsersToggle(!allUsersToggle)
    }

    const ToggleNewUser = (e) => {
        e.preventDefault()
        setCreateUser(!createUser)
        setNewUserError('')
    }

    const ToggleLogoUpload = (e) => {
        e.preventDefault()
        setLogoUploadToggle(!logoUploadToggle)
        setLogoError('')
    }

    const UploadLogo = (e) => {
        e.preventDefault()

        if (selectedFile !== undefined) {
            const uploadTask = fire.storage().ref(company).put(selectedFile);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    // error function ....
                    console.log("error = ", error);
                },
                () => {
                    // complete function ....
                    fire.storage().ref(company).getDownloadURL().then(url => {
                        setLogoUploadSuccess(true)

                        const logoRef = fire.database().ref(`Clients/${company}/`)
                        logoRef.update({ logo: url });

                        GetClientLogo()
                        GetChosenClientLogo()

                        setSelectedFile(undefined)
                        setError('')
                        setLogoUploadToggle(false)

                        setTimeout(function () {
                            setLogoUploadSuccess(false)
                        }, 3000);
                    })
                });
        }
        else {
            setLogoError("Please select a file before uploading")
        }
    }

    const CloseSiteModal = () => {
        setSites(undefined)
        setSitesModal(false)
    }

    const CloseStageModal = () => {
        setStages(undefined)
        setStageModal(false)
    }

    const GetChosenClientLogo = () => {
        fire.database()
            .ref(`/Clients/${company}`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()

                for (var key of Object.keys(data)) {
                    if (key === "logo") {
                        setClientLogo(data.logo)
                    }
                }
            });
    }

    const DeleteCompany = (e) => {
        e.preventDefault()

        const deletedRef = fire.database().ref(`Clients/${company}/`)
        deletedRef.update({ deleted: true });

        fire.database()
            .ref(`/Clients`)
            .once('value')
            .then(snapshot => {
                var data = snapshot.val()
                var clientLoopData = []

                for (var key of Object.keys(data)) {
                    if (data[key].deleted !== true) {
                        clientLoopData.push({ id: key, company: key })
                    }
                }
                setClients(clientLoopData)
                setUsers([])
                setClientLogo('')
                setCompany('')
            });

    }

    const handleClick = e => {
        if (node.current !== undefined && node.current !== null) {
            if (node.current.contains(e.target)) {
                // inside click
                return;
            }
        }

        // console.log("sitesModal = ", sitesModal)
        // console.log("stageModal = ", stageModal)

        // outside click
        // if (sitesModal) {
        CloseSiteModal();
        // }
        // else if (stageModal) {
        CloseStageModal();
        // }

    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    if (!accounts) {
        return (
            <section>
                <div>
                    <h1>LOADING...</h1>
                </div>
            </section>
        )
    }

    return (
        <section>
            <div>
                <h2>Admin area</h2>
                <br />
                <form>
                    <h3>Company</h3>
                    <select style={{ fontSize: 14 }} id="accDropDown" name="accDropDown" onChange={(e) => SetUsers(e)}>
                        <option>Select Company...</option>
                        {clients.map((acc) => <option key={acc.id} value={acc.id}>{acc.company}</option>)}
                    </select>
                    <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => ToggleNewCompany(e)}>Add Company</button>
                    <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => ToggleAllUsers(e)}>Show all users companies</button>

                    <br /><br />
                    {company ?
                        <div style={{ fontSize: 14 }}>
                            <p style={{ color: "red" }}>{updatedCompanyError}</p>
                            <div>
                                <input type="text" className="input-textbox" onChange={(e) => setUpdatedCompany(e.target.value)} placeholder="Company name" />
                                <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={UpdateCompanyName}>Change Company Name</button>
                                <button className="btn btn-danger" style={{ fontSize: 14, margin: 5 }} onClick={(e) => DeleteCompany(e)}>Delete Company</button>
                            </div>
                            <br />
                            <div>
                                <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={ToggleLogoUpload}>Change Company Logo</button>
                            </div>
                            <br />
                            {logoUploadSuccess ?
                                <Alert variant="success">
                                    Logo updated successfully.
                                </Alert>
                                : null
                            }
                            {logoUploadToggle ?
                                <div>
                                    {clientLogo ?
                                        <div>
                                            <img src={clientLogo} alt={"Client Logo"} className="align-middle" height={100} width={600} />
                                        </div>
                                        :
                                        null
                                    }
                                    <div>
                                        <p style={{ color: "red" }}>{logoError}</p>
                                    </div>
                                    <input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                    <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={UploadLogo}>Upload Logo</button>
                                </div>
                                : null}
                        </div>
                        : null}
                    <br />
                    {createCompany ?
                        <div style={{ fontSize: 14 }}>
                            <div>
                                <p style={{ color: "red" }}>{newCompanyError}</p>
                            </div>
                            <input type="text" className="input-textbox" onChange={(e) => setNewCompany(e.target.value)} placeholder="Company Name" />
                            {/* <input type="text" className="input-textbox" onChange={(e) => setUserUID(e.target.value)} placeholder="User UID" /> */}
                            <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => CreateNewCompany(e)}>Create Company</button>
                        </div>
                        :
                        null}

                    {company ?
                        <div style={{ fontSize: 14 }}>
                            <p style={{ color: "red" }}>{newUserError}</p>
                            <table className="table table-hover table-responsive">
                                <thead className="table-light">
                                    <tr>
                                        <td>Name</td>
                                        <td>Email</td>
                                        <td>Site</td>
                                        <td>Active</td>
                                        <td>Action</td>
                                        <td>Delete?</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) =>
                                        <tr key={user.id} onClick={(e) => GetBoards(user.id)} style={{ backgroundColor: user.id == chosenUserId ? "#00FFFF" : "white" }}>
                                            <td>{editUserId && editUserId == user.id ? <input type="text" className="input-textbox" defaultValue={user.name} onChange={(e) => setName(e.target.value)} /> : user.name}</td>
                                            <td>{editUserId && editUserId == user.id ? <input type="text" className="input-textbox" defaultValue={user.email} onChange={(e) => setEmail(e.target.value)} /> : user.email}</td>
                                            <td>{editUserId && editUserId == user.id ? <input type="text" className="input-textbox" defaultValue={user.site} onChange={(e) => setSite(e.target.value)} /> : user.site}</td>
                                            <td>{editUserId && editUserId == user.id ? <input type="checkbox" className="form-check-input" defaultChecked={user.active} onChange={(e) => setActive(e.target.checked)} /> : <input type="checkbox" className="form-check-input" defaultChecked={user.active} disabled />}</td>
                                            <td>{editUserId && editUserId == user.id ? <div><button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => saveButtonClickHandler(e)}>Save</button><button className="btn btn-secondary" style={{ fontSize: 14, margin: 5 }} onClick={() => setEditUserId('')}>Cancel</button></div> : <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => editButtonClickHandler(e, user)}>Edit</button>}</td>
                                            <td><button className="btn btn-danger" style={{ fontSize: 14, margin: 5 }} onClick={(e) => DeleteUser(e, user)}>Delete</button></td>
                                        </tr>
                                    )}
                                    {createUser ?
                                        <tr>
                                            {/* <td><input type="text" className="input-textbox" onChange={(e) => setUserUID(e.target.value)} placeholder="User UID" /></td> */}
                                            <td><input type="text" className="input-textbox" onChange={(e) => setNewName(e.target.value)} placeholder="Name" /></td>
                                            <td><input type="text" className="input-textbox" onChange={(e) => setNewEmail(e.target.value)} placeholder="Email" /></td>
                                            <td><input type="text" className="input-textbox" onChange={(e) => setNewSite(e.target.value)} placeholder="Site" /></td>
                                            <td><button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => CreateUser(e)} disabled={deactivateCreateButon}>Create</button></td>
                                        </tr>
                                        : null}

                                </tbody>
                            </table>
                            <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => ToggleNewUser(e)}>Add User</button>
                        </div> : null}

                    {allUsersToggle ?
                        <div>
                            <p style={{ color: "red" }}>{error}</p>
                            <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                                <thead className="table-light">
                                    <tr>
                                        <td>Name</td>
                                        <td>Company Name</td>
                                        <td>Site</td>
                                        <td>Email</td>
                                        <td>Active</td>
                                        <td>Action</td>
                                        <td>Delete?</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUsers.map((user) =>
                                        <tr key={user.id} style={{ backgroundColor: editUserId && editUserId == user.id ? "#00FFFF" : "white" }}>
                                            <td>{editUserId && editUserId == user.id ? <input type="text" className="input-textbox" defaultValue={user.name} onChange={(e) => setName(e.target.value)} /> : user.name}</td>
                                            <td>{user.company}</td>
                                            <td>{editUserId && editUserId == user.id ? <input type="text" className="input-textbox" defaultValue={user.site} onChange={(e) => setSite(e.target.value)} /> : user.site}</td>
                                            <td>{editUserId && editUserId == user.id ? <input type="text" className="input-textbox" defaultValue={user.email} onChange={(e) => setEmail(e.target.value)} /> : user.email}</td>
                                            <td>{editUserId && editUserId == user.id ? <input type="checkbox" className="form-check-input" defaultChecked={user.active} onChange={(e) => setActive(e.target.checked)} /> : <input type="checkbox" className="form-check-input" defaultChecked={user.active} disabled />}</td>
                                            <td>{editUserId && editUserId == user.id ? <div><button style={{ fontSize: 14, margin: 5 }} className="btn btn-primary" onClick={(e) => saveAllButtonClickHandler(e, user)}>Save</button><button className="btn btn-secondary" style={{ fontSize: 14, margin: 5 }} onClick={() => setEditUserId('')}>Cancel</button></div> : <button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => editButtonClickHandler(e, user)}>Edit</button>}</td>
                                            <td><button className="btn btn-danger" style={{ fontSize: 14, margin: 5 }} onClick={(e) => DeleteListUser(e, user)}>Delete</button></td>
                                        </tr>
                                    )}
                                    {createAllUser ?
                                        <tr>
                                            {/* <td><input type="text" className="input-textbox" onChange={(e) => setUserUID(e.target.value)} placeholder="User UID" /></td> */}
                                            <td><input type="text" className="input-textbox" onChange={(e) => setNewName(e.target.value)} placeholder="Name" /></td>
                                            <td>
                                                <select style={{ fontSize: 14 }} id="accDropDown" name="accDropDown" onChange={(e) => setNewAllUserCompany(e.target.value)}>
                                                    <option>Select Company...</option>
                                                    {clients.map((acc) => <option key={acc.id} value={acc.id}>{acc.company}</option>)}
                                                </select>
                                            </td>
                                            <td><input type="text" className="input-textbox" onChange={(e) => setNewSite(e.target.value)} placeholder="Site" /></td>
                                            <td><input type="text" className="input-textbox" onChange={(e) => setNewEmail(e.target.value)} placeholder="Email" /></td>
                                            <td><input type="checkbox" className="form-check-input" onChange={(e) => setNewActive(e.target.checked)} defaultChecked={true} /></td>
                                            <td><button className="btn btn-primary" style={{ fontSize: 14, margin: 5 }} onClick={(e) => CreateNewAllUser(e)} disabled={deactivateCreateButon}>Create</button></td>
                                        </tr>
                                        : null}
                                </tbody>
                            </table>

                            <button className="btn btn-primary" style={{ fontSize: 14 }} style={{ fontSize: 14, margin: 5 }} onClick={(e) => ToggleNewAllUser(e)}>Add User</button>
                        </div> : null}


                    <br /><br />

                    {company && chosenUserId && !loading ?
                        <div>
                            <h3>Boards</h3>
                            <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                                <thead className="table-light">
                                    <tr>
                                        <td>List of Boards</td>
                                        <td>Activate / Deactivate</td>
                                        <td>Live Works</td>
                                        <td>Valves</td>
                                        <td>Pipe Work</td>
                                        <td>Fabrication</td>
                                        <td>Mechanical</td>
                                        <td>Grabs</td>
                                        <td>Machining</td>
                                        <td>Valve Supply</td>
                                        <td>Sourcing</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminBoards.map((brd) =>
                                        <tr style={{ backgroundColor: brd.id == rowId ? "#00FFFF" : "white" }} onClick={(e) => GetFields(brd.id)} key={brd.id}>
                                            <td>{brd.name}</td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => UpdateBoards(e, brd.name)} type="checkbox" key={brd.id} value={brd.id} defaultChecked={brd.toggle} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, liveWorksBoards, ({ [brd.id]: e.target.checked }));
                                                        setLiveWorksBoards(newEntry);
                                                    }} value="LiveWorks" type="checkbox" defaultChecked={brd.liveWorks} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, valveBoards, ({ [brd.id]: e.target.checked }));
                                                        setValveBoards(newEntry);
                                                    }} value="Valves" type="checkbox" defaultChecked={brd.valves} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, pipeWorkBoards, ({ [brd.id]: e.target.checked }));
                                                        setPipeWorkBoards(newEntry);
                                                    }} value="Pipe Work" type="checkbox" defaultChecked={brd.pipe_work} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, fabricationBoards, ({ [brd.id]: e.target.checked }));
                                                        setFabricationBoards(newEntry);
                                                    }} value="Fabrication" type="checkbox" defaultChecked={brd.fabrication} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, mechanicalBoards, ({ [brd.id]: e.target.checked }));
                                                        setMechanicalBoards(newEntry);
                                                    }} value="Mechanical" type="checkbox" defaultChecked={brd.mechanical} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, grabsBoards, ({ [brd.id]: e.target.checked }));
                                                        setGrabsBoards(newEntry);
                                                    }} value="Grabs" type="checkbox" defaultChecked={brd.grabs} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, machiningBoards, ({ [brd.id]: e.target.checked }));
                                                        setMachiningBoards(newEntry);
                                                    }} value="Machining" type="checkbox" defaultChecked={brd.machining} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, valveSupplyBoards, ({ [brd.id]: e.target.checked }));
                                                        setValveSupplyBoards(newEntry);
                                                    }} value="Valve Supply" type="checkbox" defaultChecked={brd.valve_supply} className="form-check-input" />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                    <input onChange={(e) => {
                                                        var newEntry = Object.assign({}, sourcingBoards, ({ [brd.id]: e.target.checked }));
                                                        setSourcingBoards(newEntry);
                                                    }} value="Sourcing" type="checkbox" defaultChecked={brd.sourcing} className="form-check-input" />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        : null}

                    <br />
                    {company ?
                        <h3>Fields</h3>
                        : null}
                    {/* <select style={{ fontSize: 14 }} id="boardDropDown" name="boardDropDown" disabled={!company} onChange={(e) => GetFields(e)}>
                        <option>Select Board...</option>
                        {adminBoards.map((brd) => <option key={brd.id} value={brd.id}>{brd.name}</option>)}
                    </select> */}
                    {/* <br/><br/> */}
                    {fieldsLoading ?
                        <h3>Loading...</h3>
                        : company ?
                            <div>
                                <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                                    <thead className="table-light">
                                        <tr>
                                            <td>List of Fields</td>
                                            <td>Activate / Deactivate</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {titleToggle ?
                                            null
                                            :
                                            <tr>
                                                <td>Title</td>
                                                <td>
                                                    <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                        <input onChange={(e) => UpdateFields(e)} type="checkbox" value={"Title"} defaultChecked={false} className="form-check-input" />
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        {fields.map(field =>
                                            <tr>
                                                <td onClick={(e) => field.title === "SiteName" ? GetSiteCompanies(field.id) : field.title === "Stage" ? GetStages(field.id) : null}>{field.title}</td>
                                                <td>
                                                    <div style={{ fontSize: 24, marginLeft: 50 }} className="form-check form-switch">
                                                        <input onChange={(e) => UpdateFields(e)} type="checkbox" key={field.id} value={field.id} defaultChecked={field.toggle} className="form-check-input" />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                {sitesModal ?
                                    <Modal isOpen={sitesModal} style={customStyles}>
                                        <div ref={node} style={{ fontSize: 14 }} className="modal-scroll">
                                            <div style={{ display: "flex" }}>
                                                <button className="btn btn-secondary" style={{ fontSize: 14, margin: 5 }} onClick={CloseSiteModal}>X</button>
                                                <h1 style={{ marginLeft: "40%" }}>Sites</h1>
                                            </div>
                                            <br />

                                            {siteLoading ? <h3 style={{ textAlign: "center" }}>Loading...</h3> : null}

                                            {sites ?

                                                <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <td>Sites</td>
                                                            <td>Active / Deactivate</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sites.map(site =>
                                                            <tr>
                                                                <td>{site.id}</td>
                                                                <td>
                                                                    <div style={{ fontSize: 24, marginLeft: 70 }} className="form-check form-switch">
                                                                        <input onChange={(e) => UpdateSites(e)} type="checkbox" key={site.id} value={site.id} defaultChecked={site.toggle} className="form-check-input" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                : null}
                                        </div>
                                    </Modal>
                                    : null}

                                {stageModal ?
                                    <Modal isOpen={stageModal} style={customStyles}>
                                        <div ref={node} style={{ fontSize: 14 }} className="modal-scroll">
                                            <div style={{ display: "flex" }}>
                                                <button className="btn btn-secondary" style={{ fontSize: 14, margin: 5 }} onClick={CloseStageModal}>X</button>
                                                <h1 style={{ marginLeft: "40%" }}>Stages</h1>
                                            </div>
                                            <br />

                                            {stageLoading ? <h3 style={{ textAlign: "center" }}>Loading...</h3> : null}

                                            {stages ?
                                                <table style={{ fontSize: 14 }} className="table table-hover table-responsive">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <td>Stages</td>
                                                            <td>Active / Deactivate</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stages.map(stage =>
                                                            <tr>
                                                                <td>{stage.id}</td>
                                                                <td>
                                                                    <div style={{ fontSize: 24, marginLeft: 70 }} className="form-check form-switch">
                                                                        <input onChange={(e) => UpdateStages(e)} type="checkbox" key={stage.id} value={stage.id} defaultChecked={stage.toggle} className="form-check-input" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                : null}
                                        </div>
                                    </Modal>
                                    : null}
                            </div>
                            : null}
                    <br />
                    {showSuccess ? (
                        <Alert variant="success">
                            Settings saved successfully.
                        </Alert>
                    ) : boardError ? (
                        <Alert variant="warning">
                            You must select at least one board to be active before saving.
                        </Alert>
                    ) : (
                        <div />
                    )}
                    <button style={{ margin: 5 }} className="btn btn-secondary" onClick={handleAdminBack}>Back</button>
                    {company ?
                        <button style={{ margin: 5 }} className="btn btn-primary" onClick={UpdateDatabaseBoards}>Save</button>
                        : null}
                    {superAdmin == true ? <button style={{ margin: 5 }} className="btn btn-primary" onClick={handleSuperAdminArea}>Super Admin Area</button> : null}
                    <br />
                    <br />
                </form>
            </div>
        </section>
    )
}

export default AdminArea;