import React from 'react'
import Alert from 'react-bootstrap/Alert';

const ChangePassword = (props) => {

    const { ChangePassword, handlePasswordChangeBack, setChangePasswordText, submitted, changePasswordError } = props

    return (
        <section>
            <br />
            <div>
                <p style={{ color: "red" }}>{changePasswordError}</p>
                <form>
                    <input style={{ fontSize: 18 }} placeholder="New password" type="password" required name="password" sensitive="true" onChange={(e) => setChangePasswordText(e.target.value)} /> <br />

                    {submitted ?
                        <Alert variant="success">
                            Password reset email sent if your email exists.
                        </Alert>
                    : null}
                    <br />
                    <button style={{ margin: 5 }} className="btn btn-secondary" onClick={handlePasswordChangeBack}>Back</button>
                    <button style={{ margin: 5 }} className="btn btn-primary" onClick={ChangePassword}>Change Password</button>
                </form>
            </div>
        </section>
    )
}

export default ChangePassword;